export const AssetService = (supabase) => {
  const getAllAssets = async () => {
    const { data, error } = await supabase.from("dim_asset").select("*").order("name", { ascending: true })
    return data
  }

  return {
    getAllAssets,
  }
}
