import { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { TaskContext } from "../context/TaskContext"
import { TaskService } from "../service/TaskService"
import { useSupabase } from "./context/useSupabase"
import { useLoading } from "./utils/useLoading"

export const useTasks = () => {
  const [tasks, setTasks] = useState([])
  const [assetFilter, setAssetFilter] = useState(null)

  const { selectedTask, setSelectedTask } = useContext(TaskContext)

  const { isLoading, setIsLoading, reload, refresh } = useLoading()
  const { toast } = useContext(GlobalContext)
  const supabase = useSupabase()

  const service = useMemo(() => TaskService(supabase), [supabase])

  const fetch = async () => {
    setIsLoading(true)
    await service.getTasks({ asset: assetFilter }).then(setTasks)
    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [reload])

  useEffect(() => {
    if (tasks.length > 0 && selectedTask) setSelectedTask(tasks.find((task) => task.id === selectedTask.id))
  }, [tasks])

  const updateStatus = async (id, status) => {
    await service.updateStatus(id, status)
    refresh()
  }

  const newTask = async (task) => {
    await service.newTask(task)
    toast.success({ message: "Tarefa criada" })
    refresh()
  }

  const updateTask = async (task) => {
    await service.updateTask(task)
    toast.success({ message: "Tarefa atualizada" })
    refresh()
  }

  const deleteTask = async () => {
    await service.deleteTask(selectedTask.id)
    toast.success({ message: "Tarefa excluída" })
    refresh()
  }

  return {
    tasks,
    assetFilter,
    setAssetFilter,
    isLoading,
    setIsLoading,
    updateStatus,
    newTask,
    updateTask,
    deleteTask,
  }
}
