import { Button, DatePicker, Flex } from "antd"
import { useContext } from "react"
import { CommentsContext } from "../../../context/CommentsContext"
import { spaces } from "../../../utils/styles"
import { TeraAvatar } from "../../atoms/TeraAvatar"
import { TextRich } from "./TextRich"
import dayjs from "dayjs"

export const AddOrEditComment = ({ style, value, setValue, author, onSubmit, onCancel }) => {
  const { date, setDate } = useContext(CommentsContext)

  return (
    <Flex style={{ gap: spaces.space2, width: "100%", ...style }}>
      <TeraAvatar name={author} />
      <Flex vertical style={{ width: "100%", gap: spaces.space1 }}>
        <TextRich value={value} setValue={setValue} placeholder="Digite um novo comentário" height={150} />
        <Flex gap={spaces.space1}>
          <DatePicker
            showTime
            placeholder="Selecione a data e a hora"
            style={{ width: 200 }}
            value={dayjs(date)}
            onChange={(date) => setDate(date?.format("YYYY-MM-DD HH:mm:ss"))}
          />
          <Button type="primary" onClick={onSubmit} disabled={!value}>
            Salvar comentário
          </Button>
          <Button onClick={onCancel}>
            Cancelar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
