import { CaretDownOutlined, CaretRightOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import { Flex, Skeleton, Table, Tooltip } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { PositionsContext } from "../../../../context/PositionsContext"
import { ASSET_VISUALIZATION_TYPE, CURRENCY } from "../../../../utils/enums"
import { borders, colors, spaces } from "../../../../utils/styles"
import { filterChildrenRecursively, getAllKeys } from "../../../../utils/utils"
import { Loader } from "../../../atoms/animations/Loader"
import { MoneyLabel } from "../../../atoms/formatters/MoneyLabel"
import { PercentLabel } from "../../../atoms/formatters/PercentLabel"
import { SimpleRoundedValueLabel } from "../../../atoms/formatters/SimpleRoundedValueLabel"
import { AssetInfoModal } from "../../modals/AssetInfoModal"
import "./AssetsPositionTable.css"

export const AssetsPositionTable = ({ columnWidth = 100, expandAll = false }) => {
  const skeleton = <Skeleton.Input active />

  const { isLoading, customerOrFund, visualizationType, isToShowOnlyAssets, positions, customersOrFunds } = useContext(PositionsContext)

  const [assetToShowModal, setAssetToShowModal] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [allKeys, setAllKeys] = useState([])

  useEffect(() => setAllKeys(getAllKeys(positions)), [positions])
  useEffect(() => setExpandedRowKeys(expandAll ? allKeys : []), [expandAll])

  const onExpand = (record) => {
    const keys = expandedRowKeys.includes(record.key) ? expandedRowKeys.filter((key) => key !== record.key) : [...expandedRowKeys, record.key]
    setExpandedRowKeys(keys)
  }

  const assetClassColumn = {
    title: "Ativo",
    dataIndex: "assetClass",
    key: "assetClass",
    align: "left",
    fixed: customerOrFund ? false : "left",
    render: (text, record) => {
      if (isLoading) return skeleton

      const isParent = record.isParent

      return (
        <span
          style={{
            textAlign: "center",
            whiteSpace: "nowrap",
            fontWeight: isParent || isToShowOnlyAssets ? "bold" : "normal",
            cursor: "pointer",
          }}
          className={isParent ? "smartlook-hide" : ""}
          onClick={() => (isParent ? onExpand(record) : setAssetToShowModal(record))}
          data-hj-suppress
        >
          {text}
          {record.isIlliquid && (
            <Tooltip title="Ativo Ilíquido">
              <ExclamationCircleFilled style={{ marginLeft: spaces.space1, color: colors.primary }} />
            </Tooltip>
          )}
        </span>
      )
    },
  }

  const isPercent = visualizationType === ASSET_VISUALIZATION_TYPE.percent_on_total.value
  const isAmountBRL = visualizationType === ASSET_VISUALIZATION_TYPE.amount_brl.value
  const isAmountUSD = visualizationType === ASSET_VISUALIZATION_TYPE.amount_usd.value
  const isQuantity = visualizationType === ASSET_VISUALIZATION_TYPE.quantity.value

  const columns = customersOrFunds.map((customer) => ({
    title: customer,
    dataIndex: customer,
    key: customer,
    className: "nowrap",
    width: customerOrFund ? "calc(100vw - 500px)" : columnWidth,
    render: (value) => {
      if (isLoading) {
        return skeleton
      }
      if (isPercent) {
        return <PercentLabel value={value} data-hj-suppress />
      }
      if (isAmountBRL) {
        return <MoneyLabel value={value} currency={CURRENCY.brl} data-hj-suppress />
      }
      if (isAmountUSD) {
        return <MoneyLabel value={value} currency={CURRENCY.usd} data-hj-suppress />
      }
      if (isQuantity) {
        return <SimpleRoundedValueLabel value={value} data-hj-suppress />
      }

      return <p data-hj-suppress>value</p>
    },
    sorter: customerOrFund ? (a, b) => a[customer] - b[customer] : false,
  }))

  const rowClassName = (_, index) => (index % 2 === 0 ? "table-row-dark" : "")

  const expandIcon = ({ expanded, record }) => {
    if (isLoading) return null
    if (record.children === undefined) return null
    const props = {
      style: { marginRight: spaces.space2, cursor: "pointer" },
      onClick: () => onExpand(record),
    }

    return expanded ? <CaretDownOutlined {...props} /> : <CaretRightOutlined {...props} />
  }

  const cleanClasses = () => filterChildrenRecursively(positions).sort((a, b) => a.assetClass.localeCompare(b.assetClass))

  const Footer = () => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row className="table-summary-row">
          <Table.Summary.Cell index={0}>
            <b>Total</b>
          </Table.Summary.Cell>
          {customersOrFunds.map((customer) => (
            <Table.Summary.Cell index={customer} key={customer}>
              {isLoading && skeleton}
              {!isLoading && isPercent && <b>100%</b>}
              {!isLoading && isAmountBRL && (
                <b>
                  <MoneyLabel value={positions.reduce((acc, curr) => acc + curr[customer], 0)} currency={CURRENCY.brl} />
                </b>
              )}
              {!isLoading && isAmountUSD && (
                <b>
                  <MoneyLabel value={positions.reduce((acc, curr) => acc + curr[customer], 0)} currency={CURRENCY.usd} />
                </b>
              )}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  const WIDTH = "calc(100vw - 32px)"
  const HEIGHT = "calc(100vh - 185px)"

  if (isLoading) {
    return (
      <Flex style={{ justifyContent: "center", marginTop: spaces.space4, width: WIDTH, height: HEIGHT }}>
        <Loader />
      </Flex>
    )
  }

  return (
    <>
      {assetToShowModal && <AssetInfoModal asset={assetToShowModal} onClose={() => setAssetToShowModal(null)} />}
      <Table
        columns={[assetClassColumn, ...columns]}
        dataSource={isToShowOnlyAssets ? cleanClasses() : positions}
        rowClassName={rowClassName}
        pagination={false}
        expandable={{ rowExpandable: true, expandIcon, expandedRowKeys, onExpand }}
        scroll={{ y: "calc(100vh - 260px)", x: "max-content" }}
        style={{ border: borders.default, borderRadius: 6, width: WIDTH, maxHeight: HEIGHT }}
        locale={{ sortTitle: "Ordenar" }}
        size="small"
        summary={Footer}
      />
    </>
  )
}
