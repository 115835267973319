import { useContext } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const useSupabase = () => {
  const { supabase } = useContext(GlobalContext)
  return supabase
}

export const useSession = () => {
  const { session } = useContext(GlobalContext)
  return session
}
