import { CheckOutlined } from "@ant-design/icons"
import { Button, Col, Flex, Input, Modal, Row } from "antd"
import { useState } from "react"
import { usePipeline } from "../../../hooks/usePipeline"
import { spaces } from "../../../utils/styles"
import { AssetClassSelect } from "../../atoms/select/AssetClassSelect"
import { TextRich } from "../inputs/TextRich"

export const NewPipelineAssetModal = ({ onClose }) => {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [classId, setClassId] = useState(null)

  const { createPipeline } = usePipeline()

  const isValid = name && description && classId

  const FOOTER = (
    <Button
      type="primary"
      icon={<CheckOutlined />}
      disabled={!isValid}
      onClick={() => createPipeline({ name, description, status, classId }).then(onClose)}
    >
      Salvar
    </Button>
  )

  return (
    <Modal open title="Cadastrar novo deal" onCancel={onClose} width={700} footer={FOOTER}>
      <Flex vertical style={{ paddingTop: spaces.space1, paddingBottom: spaces.space1, gap: spaces.space2 }}>
        <Row gutter={spaces.space2}>
          <Col span={14}>
            <Flex style={{ gap: spaces.space1, alignItems: "center" }}>
              <b>Nome:</b>
              <Input style={{ width: "-webkit-fill-available" }} onChange={(e) => setName(e.target.value)} />
            </Flex>
          </Col>
          <Col span={10}>
            <AssetClassSelect setAssetClass={setClassId} width="-webkit-fill-available" />
          </Col>
        </Row>

        <TextRich value={description} setValue={setDescription} placeholder="Descrição sobre ativo" />
      </Flex>
    </Modal>
  )
}
