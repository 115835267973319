import dayjs from "dayjs"
import { createContext, useState } from "react"

export const CommentsContext = createContext({
  table: null,
  parentId: null,
  parentColumn: null,
  date: null,
  setDate: () => {},
  newComment: null,
  setNewComment: () => {},
  editComment: null,
  setEditComment: () => {},
  commentEdittingId: null,
  setCommentEdittingId: () => {},
  showNewComment: false,
  setShowNewComment: () => {},
})

export const CommentsProvider = ({ children, table, parentId, parentColumn }) => {
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD HH:mm:ss"))
  const [newComment, setNewComment] = useState(null)
  const [editComment, setEditComment] = useState(null)
  const [commentEdittingId, setCommentEdittingId] = useState(null)
  const [showNewComment, setShowNewComment] = useState(false)

  return (
    <CommentsContext.Provider
      value={{
        table,
        parentId,
        parentColumn,
        newComment,
        setNewComment,
        editComment,
        setEditComment,
        date,
        setDate,
        commentEdittingId,
        setCommentEdittingId,
        showNewComment,
        setShowNewComment,
      }}
    >
      {children}
    </CommentsContext.Provider>
  )
}
