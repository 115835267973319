import { useContext, useEffect } from "react"
import { MODES } from "../components/atoms/select/CustomerOrFundSelect"
import { GlobalContext } from "../context/GlobalContext"
import { PositionsContext } from "../context/PositionsContext"
import { PositionsService } from "../service/PositionsService"
import { ASSET_POSITIONS_COLUMNS, getWidth } from "../utils/reportsConfig"
import { downloadExcelFile } from "../utils/utils"
import { useSupabase } from "./context/useSupabase"

export const usePositions = () => {
  const {
    isExplodedView,
    setIsLoading,
    referenceDate,
    setReferenceDate,
    customerOrFund,
    setCustomerOrFund,
    setPositions,
    setGraph,
    setCustomersOrFunds,
    datesAvailable,
    setDatesAvailable,
    hideEmptyClasses,
    mode,
    locale,
    visualizationType,
    assetName,
    reload,
  } = useContext(PositionsContext)
  const supabase = useSupabase()
  const { toast } = useContext(GlobalContext)

  const service = PositionsService({
    supabase,
    customerOrFund,
    isExplodedView,
    locale,
    referenceDate,
    hideEmptyClasses,
    visualizationType,
    mode,
    assetName,
  })

  const fetchAvailableDates = async () => {
    if (!datesAvailable || !datesAvailable.length) setDatesAvailable(await service.getDatesAvailable())
    if (!referenceDate) setReferenceDate(datesAvailable[0])
  }

  const fetchPositions = async () => {
    if (referenceDate) {
      const { customersOrFunds, positions, graph } = await service.getPositions()
      setCustomersOrFunds(customersOrFunds)
      setPositions(positions)
      setGraph(graph)
      setIsLoading(false)
    }
  }

  const handleExport = async () => {
    const data = await service.handleExportExcel()
    const columnsAvailable = Array.from(new Set(data.map((item) => Object.keys(item)).flat()))
    const columns = columnsAvailable.map((column) => ({ name: column, width: getWidth(ASSET_POSITIONS_COLUMNS, column) }))

    let filename = "POSICAO_DE_ATIVOS_"

    if (customerOrFund && mode === MODES.CUSTOMER) filename += `${data[0].Cliente.replaceAll(" ", "_")}_`
    else if (customerOrFund && mode === MODES.FUND) filename += `${data[0].Fundo.replaceAll(" ", "_")}_`
    else if (!customerOrFund && mode === MODES.CUSTOMER) filename += "CLIENTES_"
    else if (!customerOrFund && mode === MODES.FUND) filename += "FUNDOS_"

    filename += `${data[0].Data}.xlsx`

    downloadExcelFile(data, "Posição de Ativos", columns, filename)
  }

  const uploadPositions = async (file) => {
    const yyyymmdd = /^\d{4}-\d{2}-\d{2}\.txt$/

    if (!yyyymmdd.test(file.name)) {
      toast.error({
        message: "Nome do arquivo inválido",
        description: "O nome do arquivo deve seguir o padrão YYYY-MM-DD.txt da data desejada",
      })

      return false
    }

    toast.info({ message: "Enviando arquivo", description: "Aguarde enquanto o arquivo é processado" })

    await supabase.auth.refreshSession()

    return true
  }

  const checkUpload = (info) => {
    const { status, response } = info.file
    if (status === "done") toast.success({ message: "Arquivo enviado com sucesso", description: response })
    else if (status === "error") toast.error({ message: "Erro ao enviar arquivo", description: response || "Erro desconhecido" })
  }

  useEffect(() => {
    setIsLoading(true)
    fetchAvailableDates()
    fetchPositions()
  }, [customerOrFund, isExplodedView, locale, referenceDate, hideEmptyClasses, visualizationType, mode, assetName, reload])

  useEffect(() => {
    setCustomerOrFund(null)
  }, [mode])

  return { handleExport, uploadPositions, checkUpload }
}
