import { tables } from "../utils/utils"

export const PipelineService = (supabase) => {
  const getPipelineAssets = async ({ classFilter, nameFilter }) => {
    const query = supabase
      .from(tables.DIM_PIPELINE_ASSET)
      .select(
        `
          id,
          name,
          description,
          created_at,
          dim_asset_id,
          dim_asset_class(id),
          fact_pipeline_status!inner(id, status, created_at, closed_at),
          fact_pipeline_comment!left(id, content, author, created_at),
          pipeline_attachment!left(id, author, filename)
      `,
      )
      .filter("fact_pipeline_comment.deleted_at", "is", null)
      .order("created_at", { ascending: false })
      .order("created_at", { foreignTable: "fact_pipeline_comment", ascending: false })
      .order("created_at", { foreignTable: "pipeline_attachment", ascending: false })

    if (classFilter) query.filter("dim_asset_class_id", "eq", classFilter)
    if (nameFilter) query.filter("name", "ilike", `%${nameFilter}%`)

    const { data } = await query

    return data.map((asset) => ({
      id: asset.id,
      name: asset.name,
      status: asset.fact_pipeline_status.find((status) => !status.closed_at)?.status,
      statusHistory: asset.fact_pipeline_status.map((status) => ({ ...status, closedAt: status.closed_at, createdAt: status.created_at })),
      class: asset.dim_asset_class?.id,
      description: asset.description,
      comments: asset.fact_pipeline_comment,
      attachments: asset.pipeline_attachment,
      createdAt: asset.created_at,
      linkedAsset: asset.dim_asset_id,
    }))
  }

  const createAsset = async ({ name, description, classId }) => {
    const {
      data: [asset],
    } = await supabase.from(tables.DIM_PIPELINE_ASSET).insert({ name, description, dim_asset_class_id: classId }).select()

    await supabase.from(tables.FACT_PIPELINE_STATUS).insert({ dim_pipeline_asset: asset.id })
  }

  const updateStatus = async (status, pipelineId) => {
    await supabase
      .from(tables.FACT_PIPELINE_STATUS)
      .update({ closed_at: new Date().toISOString() })
      .eq("dim_pipeline_asset", pipelineId)
      .is("closed_at", "null")

    await supabase.from(tables.FACT_PIPELINE_STATUS).insert({ dim_pipeline_asset: pipelineId, status }).single()
  }

  const updateClass = async (classId, pipelineId) => {
    await supabase.from(tables.DIM_PIPELINE_ASSET).update({ dim_asset_class_id: classId }).eq("id", pipelineId)
  }

  const updateName = async (name, pipelineId) => {
    await supabase.from(tables.DIM_PIPELINE_ASSET).update({ name }).eq("id", pipelineId)
  }

  const updateDescription = async (description, pipelineId) => {
    await supabase.from(tables.DIM_PIPELINE_ASSET).update({ description }).eq("id", pipelineId)
  }

  const updateLinkedAsset = async (linkedAsset, pipelineId) => {
    await supabase.from(tables.DIM_PIPELINE_ASSET).update({ dim_asset_id: linkedAsset }).eq("id", pipelineId)
  }

  return {
    getPipelineAssets,
    createAsset,
    updateStatus,
    updateClass,
    updateName,
    updateDescription,
    updateLinkedAsset,
  }
}
