import * as d3 from "d3"
import React, { useEffect, useRef, useState } from "react"

export const AssetLiabilityManagementPlot = ({
  expectedReturn,
  tax,
  consumption,
  inflation,
  patrimony,
  width = 1200,
  height = 400,
  maxYears = 20,
}) => {
  const svgRef = useRef(null)
  const tooltipRef = useRef(null) // Ref para o tooltip

  const [tooltip, setTooltip] = useState({
    display: false,
    data: {},
    x: 0,
    y: 0,
    label: "",
  })

  const calculateCumulativeData = (annualRate, maxYears, daysPerYear = 365, cumulativeKey, consumptionArray = []) => {
    const dailyRate = Math.pow(1 + annualRate / 100, 1 / daysPerYear) - 1
    const totalDays = maxYears * daysPerYear
    const cumulativeData = []
    let cumulativeValue = 1

    for (let day = 0; day <= totalDays; day++) {
      if (day > 0) cumulativeValue *= 1 + dailyRate
      const year = day / daysPerYear

      if (consumptionArray.length > 0 && day > 0 && day % daysPerYear === 0) {
        const currentYear = day / daysPerYear
        const consumptionEntry = consumptionArray.find((c) => c.year === currentYear)
        const consumptionValue = consumptionEntry ? consumptionEntry.consumption : 0
        cumulativeValue *= 1 - consumptionValue
      }

      cumulativeData.push({ day, year, [cumulativeKey]: cumulativeValue })
    }

    return cumulativeData
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    svg.selectAll("*").remove()

    const margin = { top: 20, right: 60, bottom: 50, left: 60 }

    svg.attr("width", width).attr("height", height)

    const inflationData = calculateCumulativeData(inflation, maxYears, 365, "cumulativeInflation")
    const expectedReturnData = calculateCumulativeData(expectedReturn, maxYears, 365, "cumulativeExpectedReturn")
    const netAnnualReturn = expectedReturn * (1 - tax / 100)
    const expectedReturnLiquidData = calculateCumulativeData(netAnnualReturn, maxYears, 365, "cumulativeExpectedReturnLiquid")
    const expectedReturnLiquidNetConsumptionData = calculateCumulativeData(
      netAnnualReturn,
      maxYears,
      365,
      "cumulativeExpectedReturnLiquidNetConsumption",
      consumption,
    )

    const yMaxValue =
      d3.max([
        d3.max(inflationData, (d) => d.cumulativeInflation),
        d3.max(expectedReturnData, (d) => d.cumulativeExpectedReturn),
        d3.max(expectedReturnLiquidData, (d) => d.cumulativeExpectedReturnLiquid),
        d3.max(expectedReturnLiquidNetConsumptionData, (d) => d.cumulativeExpectedReturnLiquidNetConsumption),
      ]) * patrimony

    const yScaleRight = d3
      .scaleLinear()
      .domain([1 * patrimony, yMaxValue])
      .range([height - margin.bottom, margin.top])

    const xScale = d3
      .scaleLinear()
      .domain([0, maxYears])
      .range([margin.left, width - margin.right])

    const xAxisGrid = d3
      .axisBottom(xScale)
      .tickSize(-(height - margin.top - margin.bottom))
      .tickFormat("")

    const yAxisGridRight = d3
      .axisRight(yScaleRight)
      .tickSize(-(width - margin.left - margin.right))
      .tickFormat("")

    // Adicionar grade do eixo X
    svg
      .append("g")
      .attr("class", "x grid")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(xAxisGrid)
      .selectAll("line")
      .attr("stroke", "lightgrey")
      .attr("stroke-opacity", 0.7)

    // Adicionar grade do eixo Y direito
    svg
      .append("g")
      .attr("class", "y grid right")
      .attr("transform", `translate(${width - margin.right}, 0)`)
      .call(yAxisGridRight)
      .selectAll("line")
      .attr("stroke", "lightgrey")
      .attr("stroke-opacity", 0.7)

    // Adicionar eixo X
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .style("font-size", "12px")

    // Rótulo do eixo X
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - margin.bottom + 40)
      .attr("fill", "black")
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .text("Years")

    // Adicionar eixo Y direito sem casas decimais
    svg
      .append("g")
      .attr("transform", `translate(${width - margin.right},0)`)
      .call(
        d3.axisRight(yScaleRight).tickFormat((d) => `${d.toFixed(0)} MM`), // Removido casas decimais
      )
      .selectAll("text")
      .style("font-size", "12px")

    // Rótulo do eixo Y direito
    svg
      .append("text")
      .attr("x", height / 2)
      .attr("y", width - margin.right + 40)
      .attr("transform", "rotate(90)")
      .attr("fill", "black")
      .attr("text-anchor", "middle")
      .style("font-size", "16px")
      .text("Patrimônio Final")

    // Função para desenhar as linhas e adicionar tooltips
    const drawLine = (data, key, color, label) => {
      const line = d3
        .line()
        .x((d) => xScale(d.year))
        .y((d) => yScaleRight(d[key] * patrimony))

      // Adicionar a linha
      svg
        .append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 2)
        .attr("d", line)
        .attr("class", "line") // Classe para seleção futura
        .on("mouseover", function (event, d) {
          d3.select(this).attr("stroke-width", 4) // Destacar a linha
          setTooltip((prev) => ({
            ...prev,
            display: true,
            label: label,
          }))
        })
        .on("mousemove", function (event, d) {
          const [mouseX, mouseY] = d3.pointer(event)
          const x0 = xScale.invert(mouseX)
          const bisect = d3.bisector((d) => d.year).left
          const index = bisect(data, x0)
          const selectedData = data[index] || data[data.length - 1]

          setTooltip({
            display: true,
            data: selectedData,
            x: xScale(selectedData.year) + margin.left,
            y: yScaleRight(selectedData[key] * patrimony),
            label: label,
          })
        })
        .on("mouseout", function () {
          d3.select(this).attr("stroke-width", 2) // Resetar a linha
          setTooltip((prev) => ({
            ...prev,
            display: false,
          }))
        })
    }

    // Desenhar todas as linhas
    drawLine(inflationData, "cumulativeInflation", "red", "Inflation")
    drawLine(expectedReturnData, "cumulativeExpectedReturn", "blue", "Expected Return")
    drawLine(expectedReturnLiquidData, "cumulativeExpectedReturnLiquid", "green", "Expected Return Liquid")
    drawLine(
      expectedReturnLiquidNetConsumptionData,
      "cumulativeExpectedReturnLiquidNetConsumption",
      "purple",
      "Expected Return Liquid Net Consumption",
    )

    // Adicionar legenda
    const legendData = [
      { color: "red", label: "Inflation" },
      { color: "blue", label: "Expected Return" },
      { color: "green", label: "Expected Return Liquid" },
    ]

    if (consumption.length > 0)
      legendData.push({
        color: "purple",
        label: "Expected Return Liquid Net Consumption",
      })

    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${margin.left + 10}, ${margin.top + 8})`)

    const legendItemHeight = 20

    legendData.forEach((item, index) => {
      const legendRow = legend.append("g").attr("transform", `translate(0, ${index * legendItemHeight})`)
      legendRow.append("rect").attr("x", 0).attr("y", 0).attr("width", 20).attr("height", 10).style("fill", item.color)
      legendRow.append("text").attr("x", 25).attr("y", 10).attr("fill", "black").style("font-size", "12px").text(item.label)
    })
  }, [expectedReturn, tax, consumption, inflation, patrimony, width, height, maxYears])

  return (
    <div style={{ position: "relative" }}>
      <svg ref={svgRef}></svg>
      {tooltip.display && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            top: tooltip.y - 50, // Ajuste conforme necessário
            left: tooltip.x + 10, // Ajuste conforme necessário
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "8px",
            borderRadius: "4px",
            pointerEvents: "none",
            fontSize: "12px",
            whiteSpace: "nowrap",
          }}
        >
          <div>
            <strong>{tooltip.label}</strong>
          </div>
          <div>Year: {tooltip.data.year.toFixed(2)}</div>
          <div>Value: {(tooltip.data[Object.keys(tooltip.data).find((key) => key.startsWith("cumulative"))] * patrimony).toFixed(0)} MM</div>
        </div>
      )}
    </div>
  )
}
