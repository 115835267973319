import { PlusOutlined, WarningOutlined } from "@ant-design/icons"
import { DndContext } from "@dnd-kit/core"
import { restrictToWindowEdges } from "@dnd-kit/modifiers"
import { Badge, Button, Card, Flex, Tag, Typography } from "antd"
import { useContext } from "react"
import { TaskContext } from "../../../context/TaskContext"
import { useTasks } from "../../../hooks/useTasks"
import { spaces } from "../../../utils/styles"
import { formatDate, tasksStatus } from "../../../utils/utils"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { Draggable } from "../../atoms/drag-and-drop/Draggable"
import { Droppable } from "../../atoms/drag-and-drop/Droppable"
import { KanbanPane } from "../../molecules/KanbanPane"
import { TaskModal } from "../../molecules/modals/TaskModal"

export const Tasks = () => {
  const { tasks, isLoading, updateStatus, updateTask, newTask, deleteTask } = useTasks()
  const { openModal, selectedTask } = useContext(TaskContext)

  const filteredByStatus = {
    [tasksStatus.PLANNED.text]: [],
    [tasksStatus.ON_GOING.text]: [],
    [tasksStatus.DONE.text]: [],
  }

  tasks.forEach((task) => filteredByStatus[task.status].push(task))

  const isLate = (task) => {
    if (!task?.expired_at) return false
    if (task?.status === tasksStatus.DONE.text) return false
    return new Date(task?.expired_at) < new Date()
  }

  const handleDragEnd = async (event) => {
    const { active, over, delta } = event

    const notMovedCursor = delta && delta.x === 0 && delta.y === 0

    if (notMovedCursor) return openModal(tasks.find((task) => task.id === active.id))

    const notChanged = active.id === over.id

    if (notChanged) return

    const taskId = active.id
    const status = over.id
    await updateStatus(taskId, status)
  }

  const onSubmit = (taskUpdated) => {
    if (selectedTask) updateTask(taskUpdated)
    else newTask(taskUpdated)
  }

  if (isLoading) return <LoaderFullScreen />

  return (
    <>
      <TaskModal onSubmit={onSubmit} onDelete={deleteTask} />
      <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2 }}>
        <Flex gap={spaces.space1}>
          <Button type="primary" onClick={() => openModal()}>
            <PlusOutlined />
            Nova tarefa
          </Button>
        </Flex>
        <Flex style={{ gap: spaces.space2 }}>
          <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]}>
            {Object.values(tasksStatus).map((status) => (
              <KanbanPane key={status.text} text={status.text} description={status.description} icon={status.icon} width="-webkit-fill-available">
                <Droppable key={status.text} id={status.text} style={{ padding: spaces.space1, minHeight: "75vh" }}>
                  <Flex vertical gap={spaces.space1}>
                    {filteredByStatus[status.text].map((task) => (
                      <Draggable key={task.id} id={task.id} onClick={() => openModal(task)}>
                        <Badge.Ribbon
                          text={
                            <Flex gap={spaces.space1}>
                              {task.expired_at ? (
                                <>
                                  {isLate(task) ? <WarningOutlined /> : ""}
                                  {formatDate(task.expired_at)}
                                </>
                              ) : (
                                "Sem prazo"
                              )}
                            </Flex>
                          }
                          color={isLate(task) ? "red" : "green"}
                          key={task.id}
                        >
                          <Card styles={{ body: { display: "flex", flexDirection: "row" } }} style={{ cursor: "pointer" }}>
                            <Flex>
                              {task.customer && <Tag color={task.customerColor}>{task.customer}</Tag>}
                              {task.fund && <Tag color={task.fundColor}>{task.fund}</Tag>}
                            </Flex>
                            <Typography.Text ellipsis>{task.title}</Typography.Text>
                          </Card>
                        </Badge.Ribbon>
                      </Draggable>
                    ))}
                  </Flex>
                </Droppable>
              </KanbanPane>
            ))}
          </DndContext>
        </Flex>
      </Flex>
    </>
  )
}
