import { useDroppable } from "@dnd-kit/core"
import React from "react"
import { borders, colors } from "../../../utils/styles"

export const Droppable = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  })

  const style = {
    borderRadius: borders.radius,
    backgroundColor: isOver ? "#d8d8d8" : "transparent",
    ...props.style,
  }

  return (
    <div ref={setNodeRef} style={style} onClick={props.onClick}>
      {props.children}
    </div>
  )
}
