import dayjs from "dayjs"
import { useContext, useEffect, useMemo, useState } from "react"
import { CommentsContext } from "../../context/CommentsContext"
import { GlobalContext } from "../../context/GlobalContext"
import { CommentService } from "../../service/CommentService"
import { useAuth } from "../context/useAuth"
import { useSupabase } from "../context/useSupabase"

export const useComments = () => {
  const [reload, setReload] = useState(0)
  const [history, setHistory] = useState([])

  const {
    table,
    parentId,
    parentColumn,
    newComment,
    setNewComment,
    editComment,
    setEditComment,
    commentEdittingId,
    setCommentEdittingId,
    date,
    setDate,
    showNewComment,
    setShowNewComment,
  } = useContext(CommentsContext)
  const { toast } = useContext(GlobalContext)

  const supabase = useSupabase()
  const { name: author } = useAuth()

  const service = useMemo(() => CommentService(supabase, table, parentColumn), [supabase])

  useEffect(() => {
    parentId && service.getAllComments(parentId).then(setHistory)
  }, [reload, parentId])

  const refresh = () => setReload((prev) => prev + 1)

  const prepareCreate = () => {
    setDate(dayjs())
    setShowNewComment(true)
  }

  const cancelCreate = () => {
    setNewComment("")
    setDate(dayjs())
    setShowNewComment(false)
  }

  const create = async () => {
    try {
      await service.addComment(newComment, date, author, parentId)
      toast.success({ message: "Comentário adicionado" })
    } catch (error) {
      toast.error({ message: "Erro ao adicionar comentário" })
    }

    cancelCreate()
    refresh()
  }

  const prepareEdit = (comment) => {
    setCommentEdittingId(comment.id)
    setEditComment(comment.content)
    setDate(comment.created_at)
  }

  const cancelEdit = () => {
    setEditComment("")
    setCommentEdittingId(null)
    setDate(dayjs())
  }

  const edit = async () => {
    try {
      await service.editComment(commentEdittingId, date, editComment)
      toast.success({ message: "Comentário editado" })
    } catch (error) {
      toast.error({ message: "Erro ao editar comentário" })
    }

    cancelEdit()
    refresh()
  }

  const deleteComment = async (id) => {
    try {
      await service.deleteComment(id)
      toast.success({ message: "Comentário removido" })
    } catch (error) {
      toast.error({ message: "Erro ao remover comentário" })
    }

    refresh()
  }

  return {
    history,
    create,
    edit,
    deleteComment,
    cancelCreate,
    cancelEdit,
    commentEdittingId,
    prepareCreate,
    prepareEdit,
    newComment,
    setNewComment,
    editComment,
    setEditComment,
    showNewComment,
  }
}
