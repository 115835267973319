import { createContext, useState } from "react"

export const TaskContext = createContext({
  isToOpenModal: false,
  setIsToOpenModal: () => {},
  selectedTask: null,
  setSelectedTask: () => {},
})

export const TaskProvider = ({ children }) => {
  const [selectedTask, setSelectedTask] = useState(null)
  const [isToOpenModal, setIsToOpenModal] = useState(false)
  const [forceReload, setForceReload] = useState(0)

  const openModal = (task) => {
    setIsToOpenModal(true)
    setSelectedTask(task)
  }

  const closeModal = () => {
    setIsToOpenModal(false)
    setSelectedTask(null)
  }

  const reload = () => setForceReload(forceReload + 1)

  return (
    <TaskContext.Provider value={{ selectedTask, setSelectedTask, isToOpenModal, setIsToOpenModal, openModal, closeModal, reload }}>
      {children}
    </TaskContext.Provider>
  )
}
