import { useDraggable } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import React from "react"

export const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    border: "none",
    zIndex: 1,
    padding: 0
  }

  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes} onClick={props.onClick}>
      {props.children}
    </button>
  )
}
