import React, { useEffect, useRef } from "react"
import cytoscape from "cytoscape"
import { borders, colors, spaces } from "../../utils/styles"

export const GraphViewer = ({ elements }) => {
  const cyRef = useRef(null)

  useEffect(() => {
    const cy = cytoscape({
      container: cyRef.current,
      style: [
        {
          selector: "node",
          style: {
            "background-color": (node) => (node.data("bold") ? "black" : colors.primary),
            "font-weight": (node) => (node.data("bold") ? "bold" : "normal"),
            "text-valign": "bottom",
            "text-margin-y": spaces.space1,
            label: "data(label)",
          },
        },
        {
          selector: "edge",
          style: {
            width: 2,
            "line-color": "#ccc",
            "target-arrow-color": "black",
            "target-arrow-shape": "triangle",
            "curve-style": "bezier",
          },
        },
        {
          selector: ".highlighted",
          style: {
            "background-color": "#61bffc",
            "line-color": "#61bffc",
            "target-arrow-color": "#61bffc",
            "transition-property": "background-color, line-color, target-arrow-color",
            "transition-duration": "0.2s",
          },
        },
      ],
      elements,
      layout: {
        name: "breadthfirst",
        directed: true,
        grid: true,
        avoidOverlap: true,
        roots: `#${elements.start}`,
        maximal: true,
      },
    })

    const startNode = cy.$(`#${elements.start}`)
    const endNode = cy.$(`#${elements.end}`)

    function findAllPaths(start, end, visited = new Set()) {
      if (start.id() === end.id()) return [[start]]

      visited.add(start.id())

      let paths = []

      start.outgoers("edge").forEach((edge) => {
        let targetNode = edge.target()
        if (!visited.has(targetNode.id())) {
          let subPaths = findAllPaths(targetNode, end, visited)
          subPaths.forEach((path) => {
            paths.push([start, edge, ...path])
          })
        }
      })

      visited.delete(start.id())
      return paths
    }

    const allPaths = findAllPaths(startNode, endNode)

    const highlightPath = (element) => allPaths.forEach((path) => path.includes(element) && path.forEach((ele) => ele.addClass("highlighted")))

    const removeHighlight = () => cy.elements().removeClass("highlighted")

    cy.on("mouseover", "edge", (event) => highlightPath(event.target))
    cy.on("mouseout", "edge", (_) => removeHighlight())
    cy.on("mouseover", "node", (event) => highlightPath(event.target))
    cy.on("mouseout", "node", (_) => removeHighlight())

    return () => cy.destroy()
  }, [elements])

  return <div ref={cyRef} style={{ width: "100%", height: "400px", border: borders.default, borderRadius: 6 }} />
}
