import { ArrowDownOutlined, ArrowUpOutlined, PieChartFilled, XFilled } from "@ant-design/icons"
import { Card, Flex, Space, Statistic, Table } from "antd"
import { useEffect, useState } from "react"
import { useStorage } from "../../../hooks/utils/useStorage"
import { borders, colors, spaces } from "../../../utils/styles"

export const PortfolioModel = () => {
  const [portfolioOffshoreGraph, setPortfolioOffshoreGraph] = useState(null)
  const [portfolioOnshoreGraph, setPortfolioOnshoreGraph] = useState(null)
  const { getImageURLWithToken } = useStorage({})

  useEffect(() => {
    const folder = "asset-allocation/portfolio-model"
    getImageURLWithToken(folder, "portfolio_model_offshore.png").then(setPortfolioOffshoreGraph)
    getImageURLWithToken(folder, "portfolio_model_onshore.png").then(setPortfolioOnshoreGraph)
  })

  const columns = [
    {
      title: "Classe de Ativo",
      dataIndex: "assetClass",
      key: "assetClass",
      width: 200,
    },
    {
      title: "Peso",
      dataIndex: "weight",
      key: "weight",
      width: 110,
    },
    {
      title: "Posição tática",
      dataIndex: "tacticalPosition",
      key: "tacticalPosition",
      width: 110,
      render: (value) => {
        let color = colors.blue
        let text = "Neutro"

        if (value === "BUY") {
          color = "green"
          text = "Otimista"
        }

        if (value === "SELL") {
          color = "red"
          text = "Pessimista"
        }

        return (
          <Flex style={{ gap: spaces.space1, color }}>
            <XFilled style={{ color }} />
            {text}
          </Flex>
        )
      },
    },
  ]

  const offshoreData = [
    {
      key: "1",
      assetClass: "Cash",
      weight: "10%",
      tacticalPosition: "NEUTRAL",
    },
    {
      key: "2",
      assetClass: "Core Bonds",
      weight: "30%",
      tacticalPosition: "BUY",
    },
    {
      key: "3",
      assetClass: "Credit Bonds",
      weight: "15%",
      tacticalPosition: "SELL",
    },
    {
      key: "4",
      assetClass: "Public Equity",
      weight: "20%",
      tacticalPosition: "SELL",
    },
    {
      key: "5",
      assetClass: "Private Investments",
      weight: "10%",
      tacticalPosition: "NEUTRAL",
    },
    {
      key: "6",
      assetClass: "Hedge Funds",
      weight: "10%",
      tacticalPosition: "BUY",
    },
    {
      key: "7",
      assetClass: "Gold",
      weight: "5%",
      tacticalPosition: "BUY",
    },
  ]

  const onshoreData = [
    {
      key: "1",
      assetClass: "Cash BZ",
      weight: "25%",
      tacticalPosition: "BUY",
    },
    {
      key: "2",
      assetClass: "Core Bonds BZ",
      weight: "23%",
      tacticalPosition: "SELL",
    },
    {
      key: "3",
      assetClass: "Credit Bonds BZ",
      weight: "15%",
      tacticalPosition: "BUY",
    },
    {
      key: "4",
      assetClass: "Public Equity BZ",
      weight: "15%",
      tacticalPosition: "NEUTRAL",
    },
    {
      key: "6",
      assetClass: "Hedge Funds BZ",
      weight: "17%",
      tacticalPosition: "SELL",
    },
    {
      key: "7",
      assetClass: "Public Real Estate BZ",
      weight: "5%",
      tacticalPosition: "NEUTRAL",
    },
  ]

  const offshoreStatistics = [
    {
      metric: "Expected Return",
      value: 0.051,
    },
    {
      metric: "Expected Return (Liquid)",
      value: 0.043,
    },
    {
      metric: "CPI",
      value: 0.023,
    },
    {
      metric: "Vol",
      value: 0.07,
    },
    {
      metric: "Sharpe Ratio",
      value: 0.27,
    },
    {
      metric: "Max DD",
      value: -0.23,
    },
  ]

  const onshoreStatistics = [
    {
      metric: "Expected Return",
      value: 0.138,
    },
    {
      metric: "Expected Return (Liquid)",
      value: 0.118,
    },
    {
      metric: "CPI",
      value: 0.058,
    },
    {
      metric: "Vol",
      value: 0.04,
    },
    {
      metric: "Sharpe Ratio",
      value: 0.39,
    },
    {
      metric: "Max DD",
      value: -0.13,
    },
  ]

  const fillStatisticVisualConfigs = (statistics) =>
    statistics.map((statistic) => {
      if (["Expected Return (Liquid)", "Expected Return"].includes(statistic.metric)) {
        statistic.color = "green"
        statistic.icon = <ArrowUpOutlined />
        statistic.value = (statistic.value * 100).toFixed(2)
        statistic.suffix = "%"
      } else if (statistic.metric === "Max DD") {
        statistic.color = "red"
        statistic.icon = <ArrowDownOutlined />
        statistic.value = (statistic.value * 100).toFixed(2)
        statistic.suffix = "%"
      } else if (["CPI", "Vol"].includes(statistic.metric)) {
        statistic.value = (statistic.value * 100).toFixed(2)
        statistic.suffix = "%"
      }

      return statistic
    })

  const CardLocale = ({ title, dataSource, graph, statistics }) => (
    <Card title={title}>
      <Flex vertical style={{ gap: spaces.space2, alignItems: "center" }}>
        <img src={graph} width={500} height={325} />
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
          style={{ border: borders.default, borderRadius: 6 }}
        />
        <Space style={{ gap: spaces.space1 }} size="large" wrap>
          {fillStatisticVisualConfigs(statistics).map((statistic) => (
            <Card styles={{ body: { padding: spaces.space2 } }}>
              <Statistic
                title={statistic.metric}
                value={statistic.value}
                prefix={statistic.icon}
                suffix={statistic.suffix}
                valueStyle={{ color: statistic.color }}
              />
            </Card>
          ))}
        </Space>
      </Flex>
    </Card>
  )

  const Header = ({ title, weight }) => (
    <Flex style={{ width: "100%", justifyContent: "space-between" }}>
      <h4 style={{ margin: 0 }}>{title}</h4>
      <h4 style={{ margin: 0 }}>{weight * 100}%</h4>
    </Flex>
  )

  return (
    <Flex style={{ padding: spaces.space2, gap: spaces.space2, backgroundColor: colors.gray, width: "100%", justifyContent: "center" }}>
      <CardLocale
        title={<Header title="Offshore" weight={0.6} />}
        dataSource={offshoreData}
        graph={portfolioOffshoreGraph}
        statistics={offshoreStatistics}
      />
      <CardLocale
        title={<Header title="Onshore" weight={0.4} />}
        dataSource={onshoreData}
        graph={portfolioOnshoreGraph}
        statistics={onshoreStatistics}
      />
    </Flex>
  )
}
