import { CloudUploadOutlined, FileExcelFilled, MergeFilled } from "@ant-design/icons"
import { Button, Checkbox, DatePicker, Flex, Select, Space, Tooltip, Upload } from "antd"
import useNotification from "antd/es/notification/useNotification"
import dayjs from "dayjs"
import { useContext, useState } from "react"
import { PositionsContext } from "../../../context/PositionsContext"
import { useAuth } from "../../../hooks/context/useAuth"
import { usePositions } from "../../../hooks/usePositions"
import { useSession } from "../../../hooks/context/useSupabase"
import { colors, spaces } from "../../../utils/styles"
import { AssetNameFilter } from "../../atoms/input/AssetNameFilter"
import { AssetLocaleSelect } from "../../atoms/select/AssetLocaleSelect"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect"
import { VisualizationTypeSelect } from "../../atoms/select/VisualizationTypeSelect"
import { AssetsPositionTable } from "../../molecules/tables/AssetsPositionTable/AssetsPositionTable"

export const Positions = () => {
  const { handleExport, uploadPositions, checkUpload } = usePositions()
  const { isManagementTeam } = useAuth()
  const [_, contextHolder] = useNotification()
  const [isToExpandAll, setIsToExpandAll] = useState(false)
  const session = useSession()

  const {
    datesAvailable,
    referenceDate,
    setReferenceDate,
    hideEmptyClasses,
    setHideEmptyClasses,
    isToShowOnlyAssets,
    setIsToShowOnlyAssets,
    isExplodedView,
    setIsExplodedView,
    visualizationType,
    setVisualizationType,
    customerOrFund,
    setCustomerOrFund,
    locale,
    setLocale,
    mode,
    setMode,
    assetName,
    setAssetName,
  } = useContext(PositionsContext)

  const isAllowedDate = (current) => {
    return datesAvailable.map((date) => dayjs(date)).some((date) => date.isSame(current, "day"))
  }

  return (
    <>
      {contextHolder}
      <Flex
        gap={spaces.space2}
        style={{ width: "max-content", height: "100%", paddingLeft: spaces.space2, paddingRight: spaces.space2, paddingTop: spaces.space2 }}
        vertical
      >
        <Flex style={{ gap: spaces.space2, alignItems: "center" }}>
          <AssetNameFilter setValue={setAssetName} width={"100%"} />
          <VisualizationTypeSelect value={visualizationType} setValue={setVisualizationType} />
          <AssetLocaleSelect value={locale} setValue={setLocale} />
          <Space>
            <b>Posições:</b>
            <Select value={mode} onChange={(value) => setMode(value)} style={{ width: 150 }}>
              <Select.Option value={MODES.CUSTOMER}>Clientes</Select.Option>
              <Select.Option value={MODES.FUND}>Fundos TERA</Select.Option>
            </Select>
          </Space>
          <CustomerOrFundSelect mode={mode} value={customerOrFund} setValue={setCustomerOrFund} width={195} />
        </Flex>
        <Flex gap={spaces.space2}>
          <Space style={{ width: 498 }}>
            <Checkbox checked={hideEmptyClasses} onChange={(e) => setHideEmptyClasses(e.target.checked)} disabled={isToShowOnlyAssets}>
              Ocultar classes vazias
            </Checkbox>
            <Checkbox checked={isToShowOnlyAssets} onChange={(e) => setIsToShowOnlyAssets(e.target.checked)}>
              Mostrar somente ativos
            </Checkbox>
            <Tooltip title="Faz a explosão das posições através dos fundos TERA/VRB">
              <Checkbox checked={isExplodedView} onChange={(e) => setIsExplodedView(e.target.checked)}>
                Visão explodida
              </Checkbox>
            </Tooltip>
          </Space>
          <Space style={{ gap: spaces.space1 }}>
            <Button onClick={() => setIsToExpandAll(!isToExpandAll)} disabled={isToShowOnlyAssets} type="primary" icon={<MergeFilled />}>
              {isToExpandAll ? "Recolher tudo" : "Expandir tudo"}
            </Button>
            <Tooltip title="A exportação irá trazer a lista de ativos e classes como colunas, de acordo com os filtros selecionados.">
              <Button type="primary" style={{ backgroundColor: colors.green }} icon={<FileExcelFilled />} onClick={handleExport}>
                Exportar
              </Button>
            </Tooltip>
            <Upload
              showUploadList={false}
              accept=".txt"
              beforeUpload={uploadPositions}
              action={process.env.REACT_APP_LOTE45_REPORT_UPLOAD_URL}
              headers={{ Authorization: `Bearer ${session.access_token}` }}
              onChange={checkUpload}
            >
              <Tooltip title="Anexe o relatório All Trading Desks Overview do Lote45 para atualizar a data de referência. O nome do arquivo deve seguir o padrão YYYY-MM-DD.txt da data desejada">
                <Button type="primary" style={{ backgroundColor: "black" }} icon={<CloudUploadOutlined />} disabled={!isManagementTeam}>
                  Atualizar posições
                </Button>
              </Tooltip>
            </Upload>
          </Space>
          <Space>
            <b>Data de Referência:</b>
            <DatePicker
              disabledDate={(current) => !isAllowedDate(current)}
              value={dayjs(referenceDate)}
              onChange={(e) => setReferenceDate(e.format("YYYY-MM-DD"))}
              allowClear={false}
              format={"DD/MM/YYYY"}
              style={{ width: 125 }}
            />
          </Space>
        </Flex>
        <AssetsPositionTable
          columnWidth={mode === MODES.CUSTOMER ? 150 : 200}
          expandAll={isToExpandAll || assetName !== ""}
          showOnlyAssets={isToShowOnlyAssets}
          enableSort={customerOrFund}
          data-hj-suppress
        />
      </Flex>
    </>
  )
}
