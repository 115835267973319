import { createContext } from "react"

export const AttachmentContext = createContext({
  table: null,
  parentId: null,
  parentColumn: null,
  folder: null,
})

export const AttachmentProvider = ({ children, table, parentId, parentColumn, folder }) => {
  return (
    <AttachmentContext.Provider
      value={{
        table,
        parentId,
        parentColumn,
        folder,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  )
}
