import { BulbOutlined, CalendarOutlined, CheckOutlined, CloseOutlined, CoffeeOutlined, CrownOutlined, RocketOutlined } from "@ant-design/icons"
import * as XLSX from "xlsx"

export const tables = {
  FACT_COMPLIANCE_MONITORING: "fact_compliance_monitoring",
  FACT_PIPELINE_STATUS: "fact_pipeline_status",
  FACT_MANAGEMENT_TASK_STATUS: "fact_management_task_status",
  FACT_PIPELINE_COMMENT: "fact_pipeline_comment",
  FACT_ASSET_ALLOCATION: "fact_asset_allocation",
  FACT_ASSET_ALLOCATION_EXPLODED: "fact_asset_allocation_exploded",
  FACT_EXPECTED_RETURN: "fact_expected_return",
  FACT_DOLLAR_QUOTATION: "fact_dollar_quotation",
  FACT_MANAGEMENT_TASK_COMMENT: "fact_management_task_comment",
  DIM_STRUCTURE: "dim_structure",
  DIM_TERA_FUND: "dim_tera_fund",
  DIM_ASSET_CLASS: "dim_asset_class",
  DIM_ASSET: "dim_asset",
  DIM_CUSTOMER: "dim_customer",
  DIM_PIPELINE_ASSET: "dim_pipeline_asset",
  DIM_MANAGEMENT_TASK: "management_task",
  PIPELINE_ATTACHMENT: "pipeline_attachment",
  MANAGEMENT_TASK_ATTACHMENT: "management_task_attachment",
  VIEW_POSITIONS_DATES_AVAILABLE: "view_positions_dates_available",
}

export const filesQuantFormats = {
  PDF: "pdf",
  HTML: "html",
}

export const pipelineStatus = {
  ON_GOING: {
    text: "Em Andamento",
    description: "Em análise pela equipe",
    icon: <BulbOutlined style={{ fontSize: 24 }} />,
  },
  APPROVED: {
    text: "Aprovado",
    description: "Prontos para investimento ou já aplicados",
    icon: <CheckOutlined style={{ fontSize: 24 }} />,
  },
  STAND_BY: {
    text: "Stand By",
    description: "Aprovados, mas com impedimentos para aporte",
    icon: <CoffeeOutlined style={{ fontSize: 24 }} />,
  },
  REPPROVED: {
    text: "Reprovado",
    description: "Não passaram pelo crivo da Gestão",
    icon: <CloseOutlined style={{ fontSize: 24 }} />,
  },
}

export const tasksStatus = {
  PLANNED: {
    text: "Planejado",
    description: "Demandas e decisões que estão no radar",
    icon: <CalendarOutlined style={{ fontSize: 24 }} />,
  },
  ON_GOING: {
    text: "Em andamento",
    description: "O que está sendo feito agora",
    icon: <RocketOutlined style={{ fontSize: 24 }} />,
  },
  DONE: {
    text: "Concluído",
    description: "Feito",
    icon: <CrownOutlined style={{ fontSize: 24 }} />,
  },
}

export const classColors = {
  Cash: "#F50",
  "Cash BZ": "#F50",
  Commodities: "#2db7f5",
  "Commodities BZ": "#2db7f5",
  "Core Bonds": "#87d068",
  "Core Bonds BZ": "#87d068",
  "Credit Bonds": "#108ee9",
  "Credit Bonds BZ": "#108ee9",
  FX: "#1565C0",
  "FX BZ": "#1565C0",
  Gold: "#F57C00",
  "Gold BZ": "#F57C00",
  "Hedge Funds": "#8E24AA",
  "Hedge Funds BZ": "#8E24AA",
  "Private Investments": "#5E35B1",
  "Private Investments BZ": "#5E35B1",
  "Public Equity": "#E64A19",
  "Public Equity BZ": "#E64A19",
  "Real Estate and Infrastructure BZ": "#455A64",
}

export const round = (num, decimalPlaces = 2) => Number(num.toFixed(decimalPlaces))

export function setNestedKey(obj, keys, value) {
  let key = keys.shift()
  if (keys.length === 0) {
    obj[key] = value
  } else {
    if (!obj.hasOwnProperty(key)) {
      obj[key] = {}
    }
    setNestedKey(obj[key], keys, value)
  }
  return obj
}

export function formatDate(date) {
  if (!date) return ""
  const matches = date.match(/(\d{4})-(\d{2})-(\d{2})/)
  return `${matches[3]}/${matches[2]}/${matches[1]}`
}

export function getAllKeys(data) {
  let keys = []
  const traverse = (items) => {
    items.forEach((item) => {
      keys.push(item.key)
      if (item.children) {
        traverse(item.children)
      }
    })
  }
  traverse(data)
  return keys
}

export function filterChildrenRecursively(data) {
  let children = []

  for (const item of data) {
    if (item.children) for (const child of filterChildrenRecursively(item.children)) children.push(child)
    else children.push(item)
  }

  return children
}

export const removeObjectsDuplicates = (arr) => Array.from(new Set(arr.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item))

export const replaceSpacesByUnderlines = (str) => str.replace(/ /g, "_")

export function findPaths(graph, start, end) {
  const result = []

  function dfs(currentOrigin, path) {
    const neighbors = graph.filter((vertex) => vertex.origin === currentOrigin)

    for (const neighbor of neighbors) {
      path.push(neighbor)

      if (neighbor.target === end) result.push(...path)
      else dfs(neighbor.target, path)

      path.pop()
    }
  }

  dfs(start, [])

  return removeObjectsDuplicates(result)
}

export const saveAs = (blob, filename) => {
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.setAttribute("download", filename)

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}

export function downloadExcelFile(data, workbookName, columns, filename) {
  const worksheet = XLSX.utils.json_to_sheet(data, { header: columns.map((column) => column.name) })
  worksheet["!cols"] = columns.map((column) => ({ wch: column.width }))

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, workbookName)

  const fileData = XLSX.write(workbook, { bookType: "xlsx", type: "binary" })

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  const blob = new Blob([s2ab(fileData)], { type: "application/octet-stream" })

  saveAs(blob, filename)
}

export const getLastDateAvailable = async (supabase, table) =>
  (await supabase.from(table).select("date").order("date", { ascending: false }).limit(1)).data[0]?.date

export const fetchAllData = async (query) => {
  const limit = 1000
  let offset = 0
  let allData = []
  let hasMoreData = true

  while (hasMoreData) {
    const { data, error } = await query.range(offset, offset + limit - 1)

    if (error) {
      console.error("Error fetching data:", error)
      break
    }

    if (data.length > 0) {
      allData = allData.concat(data)
      offset += limit
    }

    if (data.length < limit) {
      hasMoreData = false
    }
  }

  return allData
}

export const formatDateTime = (date) => {
  const dataGMT0 = new Date(date)
  const dataGMTMinus3 = new Date(dataGMT0.getTime() - 3 * 60 * 60 * 1000)
  return dataGMTMinus3.toLocaleDateString("pt-BR", { timeZone: "UTC", hour: "2-digit", minute: "2-digit" })
}

export const fullSize = { height: "100%", width: "100%" }

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const findDuplicates = (array) => {
  const uniqueItems = new Set()
  const duplicates = new Set()

  array.forEach((item) => (uniqueItems.has(item) ? duplicates.add(item) : uniqueItems.add(item)))

  return Array.from(duplicates)
}

export const getInitials = (sentence) => {
  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("")
}

export const countDaysUntilToday = (dateString) => {
  const date = new Date(dateString)
  const today = new Date()
  const diffTime = Math.abs(today - date)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const replaceKey = (obj, key, newKey) => {
  if (!Object.keys(obj).includes(key)) return
  obj[newKey] = obj[key]
  delete obj[key]
}
