import { useEffect, useMemo, useState } from "react"
import { AssetService } from "../service/AssetService"
import { useSupabase } from "./context/useSupabase"

export const useAsset = () => {
  const supabase = useSupabase()
  const service = useMemo(() => AssetService(supabase), [supabase])
  const [assets, setAssets] = useState()

  const getAllAssets = async () => {
    const assets = await service.getAllAssets()
    setAssets(assets)
  }

  useEffect(() => {
    getAllAssets()
  }, [])

  return { assets }
}
