import { Flex, Select } from "antd"
import { ASSET_LOCALES } from "../../../utils/enums"
import { spaces } from "../../../utils/styles"

export const AssetLocaleSelect = ({ value, setValue }) => {
  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>Localização: </b>
      <Select onChange={setValue} style={{ width: 125 }} value={value}>
        {Object.values(ASSET_LOCALES).map((locale) => (
          <Select.Option key={locale.value} value={locale.value}>
            {locale.label}
          </Select.Option>
        ))}
      </Select>
    </Flex>
  )
}
