import { createContext } from "react"

export const GlobalContext = createContext({
  supabase: null,
  session: null,
  classes: [],
  classesParent: [],
  toast: null,
  modal: null,
})
