import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Avatar, Button, Card, Flex, Modal, Tooltip } from "antd"
import { colors, spaces } from "../../../utils/styles"
import { formatDateTime, getInitials } from "../../../utils/utils"

export const CommentViewer = ({ author, date, content, onEdit, onDelete }) => {
  const secureDelete = () => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse comentário?",
      okText: "Excluir",
      okButtonProps: { style: { backgroundColor: colors.primary } },
      cancelText: "Cancelar",
      onOk: onDelete,
    })
  }

  return (
    <Card size="small">
      <Flex gap={spaces.space2}>
        <Flex>
          <Avatar size={spaces.space5} style={{ backgroundColor: "#1890ff", fontSize: 18, marginTop: spaces.space1 }}>
            {getInitials(author)}
          </Avatar>
        </Flex>
        <Flex vertical>
          <Flex vertical gap={spaces.space1}>
            <Flex>
              <b>
                {`${author} - ${formatDateTime(date)}`}
                <Tooltip title="Editar comentário">
                  <Button type="text" icon={<EditOutlined />} style={{ marginLeft: spaces.space0 }} onClick={onEdit} />
                </Tooltip>
                <Tooltip title="Excluir comentário">
                  <Button type="text" icon={<DeleteOutlined />} onClick={secureDelete} />
                </Tooltip>
              </b>
            </Flex>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
