import { Tooltip, Typography } from "antd"

export const Ellipsis = ({ children, style = {} }) => {
  return (
    <Tooltip title={children}>
      <Typography.Text ellipsis style={style}>
        {children}
      </Typography.Text>
    </Tooltip>
  )
}
