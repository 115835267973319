import { MODES } from "../components/atoms/select/CustomerOrFundSelect"
import { ASSET_LOCALES, ASSET_VISUALIZATION_TYPE } from "../utils/enums"
import { ASSET_POSITIONS_COLUMNS } from "../utils/reportsConfig"
import { fetchAllData, setNestedKey, tables } from "../utils/utils"

const EMPTY_CLASS = "Não classificado"

export const PositionsService = ({
  supabase,
  customerOrFund,
  isExplodedView,
  locale,
  referenceDate,
  mode,
  hideEmptyClasses,
  visualizationType,
  assetName,
}) => {
  const factTable = isExplodedView ? tables.FACT_ASSET_ALLOCATION_EXPLODED : tables.FACT_ASSET_ALLOCATION
  const dimTable = mode === MODES.CUSTOMER ? tables.DIM_CUSTOMER : tables.DIM_TERA_FUND

  const getSupabaseData = async () => {
    const dimAssetQuery = supabase.from("dim_asset").select("id, name, dim_asset_class_id")
    const dimAssetClassQuery = supabase.from("dim_asset_class").select("id, name, id_parent")

    const positionsQuery = supabase
      .from(factTable)
      .select(
        `id,
       quantity,
       value_brl,
       value_usd,
       date,
       locale,
       ${dimTable} ( name ),
       dim_asset!inner ( name, is_illiquid ),
       dim_asset_class ( id, name, id_parent )`,
      )
      .filter("date", "eq", referenceDate)

    const dimToIgnore = mode === MODES.CUSTOMER ? tables.DIM_TERA_FUND : tables.DIM_CUSTOMER

    positionsQuery.filter(`${dimToIgnore}_id`, "is", null).not(dimTable, "is", null)

    if (customerOrFund) positionsQuery.filter(`${dimTable}_id`, "eq", customerOrFund)

    if (locale && locale != ASSET_LOCALES.all.value) {
      positionsQuery.filter("locale", "eq", locale)
      dimAssetQuery.filter("locale", "eq", locale)
      dimAssetClassQuery.filter("locale", "eq", locale)
    }

    if (assetName) {
      positionsQuery.filter("dim_asset.name", "ilike", `%${assetName}%`)
      dimAssetQuery.filter("name", "ilike", `%${assetName}%`)
    }

    positionsQuery.order("dim_asset ( name )")
    dimAssetClassQuery.order("id")

    const graphQuery = supabase
      .from("fact_asset_allocation")
      .select("dim_asset ( name ), dim_tera_fund ( name ), dim_customer ( name ), value_brl, value_usd")
      .filter("date", "eq", referenceDate)
      .order("dim_asset ( name )")

    const dimAsset = await fetchAllData(dimAssetQuery)

    return {
      positions: await fetchAllData(positionsQuery),
      classes: await fetchAllData(dimAssetClassQuery),
      mapAssetClasses: dimAsset.reduce((acc, { name, dim_asset_class_id }) => ({ ...acc, [name]: dim_asset_class_id }), {}),
      mapAssetId: dimAsset.reduce((acc, { name, id }) => ({ ...acc, [name]: id }), {}),
      graph: (await fetchAllData(graphQuery)).map(({ dim_asset, dim_tera_fund, dim_customer, value_brl, value_usd }) => ({
        origin: dim_customer?.name || dim_tera_fund?.name,
        target: dim_asset.name,
        value_brl,
        value_usd,
      })),
    }
  }

  const transformMapToAntdChildrenFormat = (map, classes, customers, totals, mapAssetClasses, mapAssetId) => {
    const sort = (a, b) => {
      const aIsParentAndBIsNot = a.isParent && !b.isParent
      const bIsParentAndAIsNot = !a.isParent && b.isParent
      const aIsEmptyClass = a.key === EMPTY_CLASS
      const bIsEmptyClass = b.key === EMPTY_CLASS

      if (aIsParentAndBIsNot) return -1
      if (bIsParentAndAIsNot) return 1
      if (aIsEmptyClass) return -1
      if (bIsEmptyClass) return 1
      return a.key.localeCompare(b.key)
    }

    return Object.entries(map)
      .reduce((acc, [key, value]) => {
        if (key === "isClass") return acc

        if (value.isClass) {
          const children = transformMapToAntdChildrenFormat(value, classes, customers, totals, mapAssetClasses, mapAssetId).sort(sort)

          const customersTotals = customers.reduce((acc, customer) => ({ ...acc, [customer]: 0 }), {})

          children.forEach((child) => {
            Object.entries(child).forEach(([key, value]) => {
              if (customers.includes(key)) customersTotals[key] += value
            })
          })

          return acc.push({ key, assetClass: key, isParent: true, children, ...customersTotals }), acc
        }

        const result = {
          key,
          assetClass: key,
          idClass: mapAssetClasses[key],
          idAsset: mapAssetId[key],
          locale: value.locale,
          isIlliquid: value.is_illiquid,
        }

        if (!totals) return acc.push({ ...result, ...value }), acc

        Object.entries(value).forEach(([customerName, customerValue]) => {
          if (!customers.includes(customerName)) return
          const total = totals[customerName]
          result[customerName] = customerValue / total
        })

        return acc.push(result), acc
      }, [])
      .sort(sort)
  }

  const getTotals = (positions, customers) => {
    return customers.reduce((acc, customer) => {
      acc[customer] = positions.reduce((total, { value_usd, [dimTable]: group }) => {
        if (group.name === customer) total += value_usd
        return total
      }, 0)
      return acc
    }, {})
  }

  const findPathToRoot = (classes, className) => {
    const assetClass = classes.find(({ name }) => name === className)
    const path = [assetClass.name]

    let parent = classes.find(({ id }) => id === assetClass.id_parent)

    while (parent) {
      path.unshift(parent.name)
      parent = classes.find(({ id }) => id === parent.id_parent)
    }

    return path
  }

  const getAssetsClassPath = ({ classes }) => {
    return classes.reduce((acc, { name }) => {
      acc[name] = findPathToRoot(classes, name)
      return acc
    }, {})
  }

  const generateInitialTree = ({ assetClassPath }) => {
    return Object.values(assetClassPath).reduce((acc, path) => {
      let current = acc

      path.forEach((name) => {
        if (!current[name]) current[name] = { isClass: true }
        current = current[name]
      })

      return acc
    }, {})
  }

  const getAssetsTree = (positions, classes, visualizationType) => {
    const assetClassPath = getAssetsClassPath({ classes })
    const initialTree = generateInitialTree({ assetClassPath })
    return positions.reduce((acc, { dim_asset_class, dim_asset, [dimTable]: group, value_brl, value_usd, quantity, locale }) => {
      const assetName = dim_asset.name
      const path = assetClassPath[dim_asset_class?.name]

      let value

      if (visualizationType === ASSET_VISUALIZATION_TYPE.amount_usd.value) value = value_usd
      else if (visualizationType === ASSET_VISUALIZATION_TYPE.amount_brl.value) value = value_brl
      else if (visualizationType === ASSET_VISUALIZATION_TYPE.quantity.value) value = quantity
      else value = value_usd

      setNestedKey(acc, [...path, assetName, "locale"], locale)
      setNestedKey(acc, [...path, assetName, "is_illiquid"], dim_asset.is_illiquid)

      return setNestedKey(acc, [...path, assetName, group.name], value)
    }, initialTree)
  }

  const convertToConsolidatedView = (data) => {
    const parentsConsolidatedIndexes = {
      [EMPTY_CLASS]: 0,
      Cash: 1,
      "Cash BZ": 1,
      "Core Bonds": 2,
      "Core Bonds BZ": 2,
      "Credit Bonds": 3,
      "Credit Bonds BZ": 3,
      "Credit Bonds Infra BZ": 3,
      Commodities: 4,
      "Commodities BZ": 4,
      FX: 5,
      "FX BZ": 5,
      Gold: 6,
      "Gold BZ": 6,
      "Hedge Funds": 7,
      "Hedge Funds BZ": 7,
      "Private Equity": 8,
      "Private Equity BZ": 8,
      "Public Equity": 9,
      "Public Equity BZ": 9,
      "Public Real Estate": 10,
      "Public Real Estate BZ": 10,
      "Private Investments": 11,
      "Private Investments BZ": 11,
    }

    const dataConsolidated = Object.entries(parentsConsolidatedIndexes).reduce((acc, [key, index]) => {
      if (!acc[index]) acc[index] = { key: `${key}_consolidated`, assetClass: key, isParent: true, children: [] }
      return acc
    }, [])

    data.forEach((parent) => {
      const index = parentsConsolidatedIndexes[parent.key]
      const newParent = dataConsolidated[index]

      if (!newParent) return

      if ([EMPTY_CLASS].includes(parent.key)) return (dataConsolidated[index] = parent)

      newParent.children.push(parent)

      Object.entries(parent).forEach(([key, value]) => {
        if (key === "key" || key === "assetClass" || key === "isParent" || key === "children") return
        if (!dataConsolidated[index][key]) dataConsolidated[index][key] = 0
        dataConsolidated[index][key] += value
      })
    })

    return hideEmptyClasses ? dataConsolidated.filter((parent) => parent.children.length) : dataConsolidated
  }

  const getDatesAvailable = async () =>
    (await supabase.from(tables.VIEW_POSITIONS_DATES_AVAILABLE).select("date").order("date", { ascending: false })).data.map((row) => row.date)

  const getPositions = async () => {
    let { positions, classes, mapAssetClasses, mapAssetId, graph } = await getSupabaseData({ customerOrFund, locale })

    const classesFoundSet = new Set()
    const customersOrFundsSet = new Set()

    positions.forEach((position) => {
      if (position.dim_asset_class) findPathToRoot(classes, position.dim_asset_class.name).forEach((name) => classesFoundSet.add(name))
      if (position[dimTable]) customersOrFundsSet.add(position[dimTable].name)
    })

    const customersOrFunds = Array.from(customersOrFundsSet).sort()

    if (hideEmptyClasses) classes = classes.filter(({ name }) => classesFoundSet.has(name))

    const data = transformMapToAntdChildrenFormat(
      getAssetsTree(positions, classes, visualizationType),
      classes,
      customersOrFunds,
      visualizationType === ASSET_VISUALIZATION_TYPE.percent_on_total.value ? getTotals(positions, customersOrFunds) : null,
      mapAssetClasses,
      mapAssetId,
    )

    return {
      customersOrFunds,
      positions: locale === ASSET_LOCALES.all.value ? convertToConsolidatedView(data) : data,
      graph,
    }
  }

  const handleExportExcel = async () => {
    const hasLocaleFiltered = locale !== ASSET_LOCALES.all.value

    const query = supabase
      .from(`${factTable}_class_hierarchy`)
      .select("*")
      .filter("date", "eq", referenceDate)
      .not(dimTable, "is", null)
      .order("dim_customer, dim_tera_fund, asset")

    if (customerOrFund) query.filter(`${dimTable}_id`, "eq", customerOrFund)
    if (hasLocaleFiltered) query.filter("locale", "eq", locale)

    const rows = await fetchAllData(query)

    return rows.map(({ dim_customer, dim_tera_fund, asset, date, quantity, value_usd, value_brl, locale, class: assetClass, is_illiquid }) => {
      const result = {}

      result[ASSET_POSITIONS_COLUMNS.date.title] = date
      result[ASSET_POSITIONS_COLUMNS.asset.title] = asset
      result[ASSET_POSITIONS_COLUMNS.value_usd.title] = value_usd
      result[ASSET_POSITIONS_COLUMNS.value_brl.title] = value_brl
      result[ASSET_POSITIONS_COLUMNS.quantity.title] = quantity
      result[ASSET_POSITIONS_COLUMNS.is_illiquid.title] = is_illiquid

      if (dimTable === tables.DIM_CUSTOMER) result[ASSET_POSITIONS_COLUMNS.customer.title] = dim_customer
      else result[ASSET_POSITIONS_COLUMNS.fund.title] = dim_tera_fund

      if (!hasLocaleFiltered) result[ASSET_POSITIONS_COLUMNS.local.title] = locale

      assetClass
        .split(",")
        .reverse()
        .forEach((className, index) => {
          const key = index === 0 ? ASSET_POSITIONS_COLUMNS.class_parent.title : ASSET_POSITIONS_COLUMNS[`class_level_${index}`].title
          result[key] = className
        })

      return result
    })
  }

  return {
    getDatesAvailable,
    getPositions,
    handleExportExcel,
  }
}

export const getLastDateAvailable = async (supabase, table) =>
  (await supabase.from(table).select("date").order("date", { ascending: false }).limit(1)).data[0]?.date
