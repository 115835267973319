import { Layout, Menu } from "antd"
import React from "react"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { MaturityProvider } from "../../context/MaturityContext"
import { PipelineProvider } from "../../context/PipelineContext"
import { PositionsProvider } from "../../context/PositionsContext"
import { TaskProvider } from "../../context/TaskContext"
import { spaces } from "../../utils/styles"
import { Logo } from "../atoms/images/Logo"
import { AssetAllocation } from "../templates/AssetAllocation/AssetAllocation"
import { Maturity } from "../templates/Maturity/Maturity"
import { Pipeline } from "../templates/Pipeline/Pipeline"
import { Positions } from "../templates/Positions/Positions"
import { Risk } from "../templates/Risk/Risk"
import { Similarity } from "../templates/Similarity/Similarity"
import { Tasks } from "../templates/Tasks/Tasks"

function Home() {
  const HEADER_HEIGHT = 64

  const menuItems = [
    {
      key: 1,
      path: "/posicoes",
      label: "Posições",
      children: (
        <PositionsProvider>
          <Positions />
        </PositionsProvider>
      ),
      default: true,
    },
    {
      key: 2,
      path: "/proximos-vencimentos",
      label: "Próximos vencimentos",
      children: (
        <MaturityProvider>
          <Maturity />
        </MaturityProvider>
      ),
    },
    // {
    //   key: 3,
    //   path: "/enquadramento",
    //   label: "Enquadramento",
    //   children: <Compliance />,
    // },
    {
      key: 4,
      path: "/risco",
      label: "Risco",
      children: <Risk />,
    },
    {
      key: 5,
      path: "/similaridade",
      label: "Similaridade",
      children: <Similarity />,
    },
    {
      key: 6,
      path: "/asset-allocation",
      label: "Asset Allocation",
      children: <AssetAllocation />,
    },
    {
      key: 7,
      path: "/pipeline",
      label: "Pipeline",
      children: (
        <PipelineProvider>
          <Pipeline />
        </PipelineProvider>
      ),
    },
    {
      key: 9,
      path: "/tarefas",
      label: "Tarefas",
      children: (
        <TaskProvider>
          <Tasks />
        </TaskProvider>
      ),
    },
  ]

  const currentPath = useLocation().pathname

  const defaultItem = menuItems.find((item) => item.default)

  return (
    <Layout style={{ height: "100vh" }}>
      <Layout.Header
        style={{
          display: "flex",
          alignItems: "center",
          paddingRight: spaces.space5,
          paddingLeft: spaces.space5,
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: HEADER_HEIGHT,
          backgroundColor: "#3a3a3a",
        }}
      >
        <Logo width={95} height={25} marginRight={spaces.space5} />
        <Menu
          mode="horizontal"
          theme="dark"
          selectedKeys={[menuItems.find((item) => currentPath.startsWith(item.path))?.key.toString()]}
          items={menuItems.map((item) => ({
            key: item.key.toString(),
            label: <Link to={item.path}>{item.label}</Link>,
          }))}
          style={{ flex: 1, minWidth: 0, backgroundColor: "transparent" }}
        />
      </Layout.Header>
      <Layout.Content
        style={{
          overflow: "auto",
          backgroundColor: "white",
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        }}
      >
        <Routes>
          <Route path="*" element={<Navigate to={defaultItem.path} />} key="default" />
          {menuItems.map((item) => (
            <Route path={item.path} element={item.children} key={item.key} />
          ))}
        </Routes>
      </Layout.Content>
    </Layout>
  )
}

export default Home
