import { Button, DatePicker, Flex, Form, InputNumber, Modal, Space, Table } from "antd"
import dayjs from "dayjs"
import { spaces } from "../../../utils/styles"

export const NewExpectedReturnModal = ({ onClose, onSubmit }) => {
  const [form] = Form.useForm()

  const offshore = {
    title: "Offshore",
    fields: [
      { name: "Expected Inflation 1Y", locale: "offshore" },
      { name: "Expected Inflation LT", locale: "offshore" },
      { name: "Cash 1Y", locale: "offshore" },
      { name: "Cash LT", locale: "offshore" },
      { name: "Global Aggregate", locale: "offshore" },
      { name: "US High Yield", locale: "offshore" },
      { name: "ACWI", locale: "offshore" },
      { name: "Private Investments", locale: "offshore" },
      { name: "Hedge Funds", locale: "offshore" },
      { name: "Gold", locale: "offshore" },
      { name: "Aggregated Commodities", locale: "offshore" },
    ],
  }

  const onshore = {
    title: "Onshore",
    fields: [
      { name: "Expected Inflation LT", locale: "onshore" },
      { name: "CDI", locale: "onshore" },
      { name: "Cash LT", locale: "onshore" },
      { name: "IMA-B", locale: "onshore" },
      { name: "IDA-DI", locale: "onshore" },
      { name: "IBOV", locale: "onshore" },
      { name: "Private Investments", locale: "onshore" },
      { name: "Hedge Funds", locale: "onshore" },
      { name: "IFIX", locale: "onshore" },
    ],
  }

  form.setFieldsValue({ date: dayjs().date(1) })

  const onFinish = (data) => {
    const dateFormatted = data.date.format("YYYY-MM-DD")
    const temp = {}

    for (const [key, value] of Object.entries(data)) {
      if (key === "date") continue

      const regex = /^(.*?)_(.*?)_(return|volatility|max_drawdown|cvar)$/
      const match = key.match(regex)

      if (match) {
        const [_, name, locale, attribute] = match
        const compositeKey = `${name}_${locale}`

        if (!temp[compositeKey]) {
          temp[compositeKey] = {
            name: name,
            locale: locale,
            return: null,
            volatility: null,
            max_drawdown: null,
            cvar: null,
            date: dateFormatted,
          }
        }

        temp[compositeKey][attribute] = value !== undefined ? value / 100 : null
      }
    }

    onSubmit(Object.values(temp))
    onClose()
  }

  const handlePaste = (e) => {
    const columnsMap = {
      0: "return",
      1: "volatility",
      2: "cvar",
      3: "max_drawdown",
    }

    e.clipboardData
      .getData("Text")
      .split("\n")
      .forEach((row, indexLine) => {
        if (indexLine >= offshore.fields.length + onshore.fields.length) return

        row.split("\t").forEach((value, indexColumn) => {
          const type = columnsMap[indexColumn]

          let field = null

          if (indexLine < offshore.fields.length) field = offshore.fields[indexLine]
          if (indexLine >= offshore.fields.length) field = onshore.fields[indexLine - offshore.fields.length]

          const parsedValue = parseFloat(value.replace("%", ""))

          if (isNaN(parsedValue)) return

          form.setFieldsValue({ [`${field.name}_${field.locale}_${type}`]: parsedValue })
        })
      })
  }

  const generatePercentInput = (name) => (
    <Form.Item name={name} style={{ margin: 0 }}>
      <InputNumber min={0} style={{ width: "80px" }} suffix="%" onPaste={handlePaste} step={0.1} />
    </Form.Item>
  )

  return (
    <Modal open title="Inserir novos retornos" onCancel={onClose} width={1200} footer={false}>
      <Form style={{ marginTop: spaces.space2 }} onFinish={onFinish} form={form}>
        <Flex style={{ gap: spaces.space1 }}>
          <Form.Item name="date" label="Data" rules={[{ required: true, message: "Campo obrigatório" }]} style={{ marginBottom: spaces.space2 }}>
            <DatePicker.MonthPicker allowClear={false} style={{ width: "100px" }} format="MM/YYYY" />
          </Form.Item>
        </Flex>
        <Flex>
          {[offshore, onshore].map(({ title, fields }) => (
            <Table
              columns={[
                { title: title, dataIndex: "name" },
                { title: "Retorno Esperado", dataIndex: "return" },
                { title: "Volatilidade", dataIndex: "volatility" },
                { title: "CVaR", dataIndex: "cvar" },
                { title: "Max Drawdown", dataIndex: "max_drawdown" },
              ]}
              pagination={false}
              size="small"
              dataSource={fields.map((field) => ({
                ...field,
                return: generatePercentInput(`${field.name}_${field.locale}_return`),
                volatility: generatePercentInput(`${field.name}_${field.locale}_volatility`),
                max_drawdown: generatePercentInput(`${field.name}_${field.locale}_max_drawdown`),
                cvar: generatePercentInput(`${field.name}_${field.locale}_cvar`),
              }))}
            />
          ))}
        </Flex>
        <Flex style={{ justifyContent: "flex-end" }}>
          <Space>
            <Button onClick={onClose}>Cancelar</Button>
            <Button type="primary" htmlType="submit">
              Salvar
            </Button>
          </Space>
        </Flex>
      </Form>
    </Modal>
  )
}
