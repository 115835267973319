import { AuditOutlined, BulbOutlined, CheckOutlined, CloseOutlined, CoffeeOutlined, SaveOutlined } from "@ant-design/icons"
import { Button, Flex, Input, Modal, Steps } from "antd"
import { useEffect, useState } from "react"
import { AttachmentProvider } from "../../../context/AttachmentsContext"
import { CommentsProvider } from "../../../context/CommentsContext"
import { usePipeline } from "../../../hooks/usePipeline"
import { colors, spaces } from "../../../utils/styles"
import { countDaysUntilToday, formatDate, pipelineStatus, tables } from "../../../utils/utils"
import { AssetClassSelect } from "../../atoms/select/AssetClassSelect"
import { AssetSelect } from "../../atoms/select/AssetSelect"
import { Attachments } from "../../organisms/Attachments"
import { Comments } from "../../organisms/Comments"
import { TextRich } from "../inputs/TextRich"

export const DetailPipelineAssetModal = ({ onClose }) => {
  const { selectedAsset: asset, isManagementTeam, updateClass, updateDescription, updateName, updateLinkedAsset } = usePipeline()

  const [description, setDescription] = useState()
  const [title, setTitle] = useState()
  const [assetClass, setAssetClass] = useState()
  const [linkedAsset, setLinkedAsset] = useState()

  useEffect(() => {
    setDescription(asset?.description)
    setTitle(asset?.name)
    setAssetClass(asset?.class)
    setLinkedAsset(asset?.linkedAsset)
  }, [asset])

  const update = () => {
    updateName(title, asset.id)
    updateDescription(description, asset.id)
    updateClass(asset.class, asset.id)
    updateLinkedAsset(linkedAsset, asset.id)
    onClose()
  }

  const hasUpdated = description !== asset?.description || title !== asset?.name || assetClass !== asset?.class || linkedAsset !== asset?.linkedAsset

  let currentStep = 0
  let hasApproved = false
  let hasRepproved = false
  let isStandBy = false

  const ICON_SIZE = 20

  let timelineItems = [
    {
      icon: <AuditOutlined style={{ fontSize: ICON_SIZE }} />,
      title: "Entrou no pipeline",
      description: formatDate(asset?.createdAt),
    },
    {
      icon: <BulbOutlined style={{ fontSize: 20 }} />,
      title: "Em diligência",
      description: "0 dias",
    },
    {
      icon: <CheckOutlined style={{ fontSize: ICON_SIZE }} />,
      title: pipelineStatus.APPROVED.text,
      description: "",
    },
    {
      icon: <CoffeeOutlined style={{ fontSize: ICON_SIZE }} />,
      title: pipelineStatus.STAND_BY.text,
      description: "",
    },
    {
      icon: <CloseOutlined style={{ fontSize: ICON_SIZE }} />,
      title: pipelineStatus.REPPROVED.text,
      description: "",
    },
  ]

  const stepMap = {
    [pipelineStatus.ON_GOING.text]: 1,
    [pipelineStatus.APPROVED.text]: 2,
    [pipelineStatus.STAND_BY.text]: 3,
    [pipelineStatus.REPPROVED.text]: 4,
  }

  asset?.statusHistory?.forEach(({ status, createdAt, closedAt }) => {
    if (status === pipelineStatus.ON_GOING.text && !closedAt) {
      const count = countDaysUntilToday(createdAt)
      timelineItems[1].description = `Há ${count} ${count === 1 ? "dia" : "dias"}`
    }

    if (status === pipelineStatus.ON_GOING.text && closedAt) {
      const begin = new Date(createdAt)
      const end = new Date(closedAt)
      const diff = Math.ceil(Math.abs(end - begin) / (1000 * 60 * 60 * 24))
      timelineItems[1].description = `Por ${diff} ${diff === 1 ? "dia" : "dias"}`
    }

    if (status === pipelineStatus.STAND_BY.text && createdAt) {
      timelineItems[3].description = formatDate(createdAt)
      isStandBy = true
    }

    if (status === pipelineStatus.APPROVED.text && createdAt) {
      timelineItems[2].description = formatDate(createdAt)
      hasApproved = true
    }

    if (status === pipelineStatus.APPROVED.text && !timelineItems[2].description) {
      timelineItems[2].description = "Não houve"
    }

    if (status === pipelineStatus.REPPROVED.text && createdAt) {
      timelineItems[4].description = formatDate(createdAt)
      hasRepproved = true
    }

    if (!closedAt) currentStep = stepMap[status]
  })

  timelineItems = timelineItems.filter((item) => {
    if (hasRepproved && item.title === pipelineStatus.APPROVED.text) return false
    if (hasRepproved && item.title === pipelineStatus.STAND_BY.text) return false
    if (hasApproved && item.title === pipelineStatus.REPPROVED.text) return false
    if (isStandBy && item.title === pipelineStatus.REPPROVED.text) return false
    if (currentStep === 1 && item.title === pipelineStatus.REPPROVED.text) return false
    return true
  })

  timelineItems = timelineItems.map((item, index) => ({
    ...item,
    icon: (
      <div
        style={{
          backgroundColor: index <= currentStep ? colors.primary : "#f8a68a",
          color: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
        }}
      >
        {item.icon}
      </div>
    ),
  }))

  return (
    <Modal
      open
      title={
        <Flex gap={spaces.space2}>
          <Input style={{ width: "100%" }} value={title} onChange={(e) => setTitle(e.target.value)} />
          <AssetClassSelect setAssetClass={setAssetClass} value={assetClass} disabled={!isManagementTeam} showLabel={false} />
          <AssetSelect
            style={{ marginRight: spaces.space4 }}
            placeholder={"Vincule um ativo da carteira"}
            value={linkedAsset}
            setValue={setLinkedAsset}
          />
        </Flex>
      }
      onCancel={onClose}
      footer={false}
      style={{ top: spaces.space4, bottom: spaces.space4 }}
      width={"80vw"}
      height={"10vh"}
    >
      <Flex vertical style={{ marginTop: spaces.space2, marginBottom: spaces.space2, gap: spaces.space2 }}>
        <TextRich value={description} setValue={setDescription} placeholder="Digite uma descrição" height={150} />

        <Steps
          current={currentStep}
          items={timelineItems}
          style={{ paddingTop: spaces.space3, paddingBottom: spaces.space3, paddingRight: spaces.space2 }}
        />

        <AttachmentProvider table={tables.PIPELINE_ATTACHMENT} parentId={asset?.id} parentColumn={"dim_pipeline_asset"} folder={`pipeline/${asset?.id}`}>
          <Attachments />
        </AttachmentProvider>

        <CommentsProvider table={tables.FACT_PIPELINE_COMMENT} parentColumn={"dim_pipeline_asset"} parentId={asset?.id}>
          <Comments />
        </CommentsProvider>

        <Button
          type="primary"
          onClick={update}
          disabled={!hasUpdated || !isManagementTeam}
          icon={<SaveOutlined />}
          style={{ width: 200, alignSelf: "flex-end" }}
        >
          Salvar
        </Button>
      </Flex>
    </Modal>
  )
}
