import { filesQuantFormats } from "../../../utils/utils"
import { StaticFileViewer } from "../../molecules/viewers/StaticFileViewer"

export const Similarity = () => {
  const files = [
    {
      title: "Dendrograma",
      path: "similaridade/dendrograma",
      folder: "similarity/dendrogram",
      format: filesQuantFormats.PDF,
    },
    {
      title: "SOM",
      path: "similaridade/som",
      folder: "similarity/som",
      format: filesQuantFormats.HTML,
    },
    {
      title: "t-SNE",
      similaridade: "similaridade/tsne",
      folder: "similarity/tsne",
      format: filesQuantFormats.HTML,
    },
    {
      title: "UMAP",
      similaridade: "similaridade/umap",
      folder: "similarity/umap",
      format: filesQuantFormats.HTML,
    },
    {
      title: "MI",
      similaridade: "similaridade/mi",
      folder: "similarity/mi-kpca",
      format: filesQuantFormats.HTML,
    },
    {
      title: "TDC",
      similaridade: "similaridade/tdc",
      folder: "similarity/tdc",
      format: filesQuantFormats.HTML,
    },
  ]

  return <StaticFileViewer files={files} />
}
