import { useContext, useEffect, useMemo, useState } from "react"
import { PipelineContext } from "../context/PipelineContext"
import { PipelineService } from "../service/PipelineService"
import { useAuth } from "./context/useAuth"
import { useSupabase } from "./context/useSupabase"
import { useLoading } from "./utils/useLoading"

export const usePipeline = () => {
  const [assets, setAssets] = useState([])
  const [showNewAssetModal, setShowNewAssetModal] = useState(false)

  const { selectedAsset, setSelectedAsset, classFilter, setClassFilter, nameFilter, setNameFilter } = useContext(PipelineContext)

  const supabase = useSupabase()
  const { isManagementTeam } = useAuth()
  const { reload, refresh } = useLoading()

  const service = useMemo(() => PipelineService(supabase), [supabase])

  const pipelineId = selectedAsset?.id

  useEffect(() => {
    if (assets.length > 0 && selectedAsset) setSelectedAsset(assets.find((asset) => asset.id === pipelineId))
  }, [assets])

  useEffect(() => {
    service.getPipelineAssets({ classFilter, nameFilter }).then(setAssets)
  }, [reload, classFilter, nameFilter])

  const createPipeline = async (pipeline) => {
    await service.createAsset(pipeline)
  }

  const updateStatus = async (status, pipelineId) => {
    await service.updateStatus(status, pipelineId)
    refresh()
  }

  const updateClass = async (classId) => {
    await service.updateClass(classId, pipelineId)
    refresh()
  }

  const updateName = async (name) => {
    await service.updateName(name, pipelineId)
    refresh()
  }

  const updateDescription = async (description) => {
    await service.updateDescription(description, pipelineId)
    refresh()
  }

  const updateLinkedAsset = async (assetId) => {
    await service.updateLinkedAsset(assetId, pipelineId)
    refresh()
  }

  const openNewAssetModal = () => setShowNewAssetModal(true)

  const closeNewAssetModal = () => {
    setShowNewAssetModal(false)
    refresh()
  }

  return {
    assets,
    selectedAsset,
    showNewAssetModal,
    createPipeline,
    updateStatus,
    updateName,
    updateClass,
    updateDescription,
    updateLinkedAsset,
    setNameFilter,
    setClassFilter,
    setSelectedAsset,
    openNewAssetModal,
    closeNewAssetModal,
    refresh,
    isManagementTeam,
  }
}
