import { useSession } from "./useSupabase"

export const useAuth = () => {
  const names = {
    "felipe.nunes@teracapital.com.br": "Felipe Nunes",
    "rafael.rocha@teracapital.com.br": "Rafael Rocha",
    "adri.costa@teracapital.com.br": "Adri Costa",
    "italo.nunes@teracapital.com.br": "Italo Nunes",
    "joao.vaz@teracapital.com.br": "João Vaz",
    "victor.koyama@teracapital.com.br": "Victor Koyama",
    "mario.quaresma@teracapital.com.br": "Mario Quaresma",
    "pedro.monteiro@teracapital.com.br": "Pedro Monteiro",
  }

  const session = useSession()

  const isManagementTeam = names[session.user.email] !== undefined

  const name = names[session.user.email] || session.user.email

  return { isManagementTeam, name }
}
