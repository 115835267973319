import { Flex, Select } from "antd"
import { ASSET_VISUALIZATION_TYPE, DEFAULT_ASSET_VISUALIZATION_TYPE } from "../../../utils/enums"
import { spaces } from "../../../utils/styles"

export const VisualizationTypeSelect = ({ value, setValue }) => {
  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      <b>Visualização:</b>
      <Select
        style={{ width: 160 }}
        options={Object.values(ASSET_VISUALIZATION_TYPE)}
        value={value}
        defaultValue={DEFAULT_ASSET_VISUALIZATION_TYPE}
        onChange={setValue}
      />
    </Flex>
  )
}
