import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, InputNumber, Row } from "antd"
import React from "react"
import { spaces } from "../../../utils/styles"
import { findDuplicates } from "../../../utils/utils"

const { Item } = Form

export const ConsumptionPercentageForm = ({ totalYears, onSubmit }) => {
  const [form] = Form.useForm()

  const validateIntervals = async (_, allValues) => {
    const allYears = []

    for (let interval of allValues || []) {
      if (!interval) continue

      const { startYear, endYear } = interval

      if (startYear > endYear) throw "O Ano Inicial deve ser menor ou igual ao Ano Final."

      for (let year = startYear; year <= endYear; year++) allYears.push(year)
    }

    const missingYears = []

    for (let year = 1; year <= totalYears; year++) {
      if (allYears.includes(year)) continue

      missingYears.push(year)
    }

    if (missingYears.length > 0) throw `Os seguintes anos não estão cobertos por nenhum intervalo: ${missingYears.join(", ")}`

    const duplicateYears = findDuplicates(allYears)

    if (duplicateYears.length > 0) throw `Os seguintes anos estão sobrepostos em múltiplos intervalos: ${duplicateYears.join(", ")}`
  }

  const onValuesChange = async (_, values) => {
    try {
      await form.validateFields()

      const { intervals } = values

      const result = intervals.flatMap((interval) =>
        Array.from({ length: interval?.endYear - interval?.startYear + 1 }, (_, i) => ({
          year: interval?.startYear + i,
          consumption: interval?.consumptionPercentage / 100,
        })),
      )

      onSubmit(result)
    } catch (error) {}
  }

  return (
    <Form form={form} onValuesChange={onValuesChange} autoComplete="off" validateTrigger="onBlur">
      <Form.List name="intervals" rules={[{ validator: validateIntervals }]}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Card key={key} style={{ marginBottom: spaces.space2 }} size="small">
                <Row gutter={spaces.space2} align="middle">
                  <Col span={7}>
                    <Item
                      {...restField}
                      name={[name, "startYear"]}
                      label="Ano Inicial"
                      rules={[{ required: true, message: "Insira o Ano Inicial" }]}
                      style={{ marginBottom: 0 }}
                      validateTrigger={[]}
                    >
                      <InputNumber min={1} max={totalYears} placeholder="Ex: 1" style={{ width: "100%" }} />
                    </Item>
                  </Col>
                  <Col span={7}>
                    <Item
                      {...restField}
                      name={[name, "endYear"]}
                      label="Ano Final"
                      rules={[{ required: true, message: "Insira o Ano Final" }]}
                      style={{ marginBottom: 0 }}
                      validateTrigger={[]}
                    >
                      <InputNumber min={1} max={totalYears} placeholder="Ex: 5" style={{ width: "100%" }} />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      {...restField}
                      name={[name, "consumptionPercentage"]}
                      label="Gasto Anual (%)"
                      rules={[{ required: true, message: "Insira o Percentual de Gasto" }]}
                      style={{ marginBottom: 0 }}
                      validateTrigger={[]}
                    >
                      <InputNumber min={0} max={100} step={0.1} placeholder="Ex: 1.5" style={{ width: "100%" }} suffix="%" />
                    </Item>
                  </Col>
                  <Col span={2}>
                    {fields.length > 1 && (
                      <MinusCircleOutlined onClick={() => remove(name)} style={{ color: "red", fontSize: "20px", cursor: "pointer" }} />
                    )}
                  </Col>
                </Row>
              </Card>
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Adicionar percentual de consumo
              </Button>
            </Form.Item>
            {errors.length > 0 && (
              <Form.Item>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </Form>
  )
}
