import { Select } from "antd"
import { useAsset } from "../../../hooks/useAsset"
import { removeAccents } from "../../../utils/string"

export const AssetSelect = ({ value, setValue, style, placeholder }) => {
  const { assets } = useAsset()

  const filterOption = (input, option) => {
    const inputNormalized = removeAccents(input.toLowerCase())
    const optionTextNormalized = removeAccents(option.children.toLowerCase())
    return optionTextNormalized.includes(inputNormalized)
  }

  return (
    <Select
      style={{ width: 600, ...style }}
      placeholder={placeholder}
      filterOption={filterOption}
      optionFilterProp="children"
      value={value}
      onChange={setValue}
      showSearch
    >
      {assets?.map((asset) => (
        <Select.Option key={asset.id} value={asset.id}>
          {asset.name}
        </Select.Option>
      ))}
    </Select>
  )
}
