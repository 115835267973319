import { classColors, replaceKey, tables } from "../utils/utils"

export const TaskService = (supabase) => {
  const getTasks = async ({ asset }) => {
    const query = supabase
      .from(tables.DIM_MANAGEMENT_TASK)
      .select(
        `
          id,
          title,
          expired_at,
          description,
          created_at,
          dim_customer (id, name, color),
          dim_asset (id, name, dim_asset_class (name)),
          dim_tera_fund (id, name, color),
          ${tables.FACT_MANAGEMENT_TASK_COMMENT}!left(id, content, author, created_at),
          ${tables.FACT_MANAGEMENT_TASK_STATUS}!inner(id, status, created_at, closed_at)
        `,
      )
      .filter("deleted_at", "is", null)
      .filter(`${tables.FACT_MANAGEMENT_TASK_COMMENT}.deleted_at`, "is", null)
      .order("created_at", { ascending: true })

    if (asset) query.filter("dim_asset_id", "eq", asset)

    const { data } = await query

    return data.map((task) => ({
      id: task.id,
      title: task.title,
      customerId: task.dim_customer?.id,
      customer: task.dim_customer?.name,
      customerColor: task.dim_customer?.color,
      fundId: task.dim_tera_fund?.id,
      fund: task.dim_tera_fund?.name,
      fundColor: task.dim_tera_fund?.color,
      assetId: task.dim_asset?.id,
      asset: task.dim_asset?.name,
      assetColor: classColors[task.dim_asset?.dim_asset_class?.name],
      status: task[tables.FACT_MANAGEMENT_TASK_STATUS].find((status) => !status.closed_at)?.status,
      statusHistory: task[tables.FACT_MANAGEMENT_TASK_STATUS].map((status) => ({
        ...status,
        closedAt: status.closed_at,
        createdAt: status.created_at,
      })),
      expired_at: task.expired_at,
      description: task.description,
      created_at: task.created_at,
      comments: task.fact_management_task_comment,
    }))
  }

  const normalizeTask = (task) => {
    replaceKey(task, "customerId", "dim_customer_id")
    replaceKey(task, "fundId", "dim_tera_fund_id")
    replaceKey(task, "assetId", "dim_asset_id")

    delete task.customerOrFund
  }

  const updateStatus = async (id, status) => {
    await supabase
      .from(tables.FACT_MANAGEMENT_TASK_STATUS)
      .update({ closed_at: new Date().toISOString() })
      .eq("dim_management_task_id", id)
      .is("closed_at", "null")

    await supabase.from(tables.FACT_MANAGEMENT_TASK_STATUS).insert({ dim_management_task_id: id, status }).single()
  }

  const newTask = async (task) => {
    normalizeTask(task)

    const {
      data: [taskCreated],
    } = await supabase.from(tables.DIM_MANAGEMENT_TASK).insert(task).select()

    await supabase.from(tables.FACT_MANAGEMENT_TASK_STATUS).insert({ dim_management_task_id: taskCreated.id })
  }

  const updateTask = async (task) => {
    const data = { ...task }
    normalizeTask(data)
    await supabase.from(tables.DIM_MANAGEMENT_TASK).update(data).match({ id: task.id })
  }

  const deleteTask = async (taskId) => {
    await supabase.from(tables.DIM_MANAGEMENT_TASK).update({ deleted_at: new Date().toISOString() }).match({ id: taskId })
  }

  return { getTasks, updateStatus, newTask, updateTask, deleteTask }
}
