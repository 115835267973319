export const AttachmentService = (supabase, table, foreignKey) => {
  const addAttachment = async (filename, author, parentId) => {
    await supabase.from(table).insert({ author, filename, [foreignKey]: parentId, created_at: new Date().toISOString() })
  }

  const deleteAttachment = async (id) => {
    await supabase.from(table).delete().eq("id", id)
  }

  const getAllAttachments = async (parentId) => {
    const { data } = await supabase.from(table).select("*").eq(foreignKey, parentId).order("created_at", { ascending: false })
    return data
  }

  return { addAttachment, deleteAttachment, getAllAttachments }
}
