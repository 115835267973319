import { Editor } from "@tinymce/tinymce-react"

export const TextRich = ({ value, setValue, placeholder = "", height = 200 }) => {
  return (
    <Editor
      value={value}
      onEditorChange={setValue}
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      init={{
        height,
        menubar: false,
        language: "pt_BR",
        toolbar: "bullist numlist | bold italic underline backcolor | link unlink | table",
        content_style: "ol ol { list-style-type: lower-alpha !important; }",
        plugins: ["lists", "link", "table"],
        statusbar: false,
        placeholder
      }}
    />
  )
}
