import { PlusOutlined } from "@ant-design/icons"
import { Button, Flex } from "antd"
import { useComments } from "../../hooks/components/useComments"
import { spaces } from "../../utils/styles"
import { AddOrEditComment } from "../molecules/inputs/AddOrEditComment"
import { CommentViewer } from "../molecules/viewers/CommentViewer"

export const Comments = ({ style = {} }) => {
  const {
    newComment,
    setNewComment,
    editComment,
    setEditComment,
    showNewComment,
    cancelEdit,
    edit,
    prepareEdit,
    history,
    deleteComment,
    commentEdittingId,
    prepareCreate,
    create,
    cancelCreate,
  } = useComments()

  return (
    <Flex vertical style={{ width: "100%", gap: spaces.space2, ...style }}>
      {showNewComment ? (
        <AddOrEditComment
          key="newComment"
          style={{ marginLeft: 10 }}
          value={newComment}
          setValue={setNewComment}
          onSubmit={create}
          onCancel={cancelCreate}
        />
      ) : (
        <Button type="dashed" onClick={prepareCreate}>
          <PlusOutlined /> Novo Comentário
        </Button>
      )}
      <Flex vertical gap={spaces.space1}>
        {history?.map((comment) =>
          commentEdittingId === comment.id ? (
            <AddOrEditComment
              key={comment.id}
              style={{ marginLeft: 10 }}
              value={editComment}
              setValue={setEditComment}
              author={comment?.author}
              onCancel={cancelEdit}
              onSubmit={edit}
            />
          ) : (
            <CommentViewer
              key={comment.id}
              author={comment.author}
              date={comment.created_at}
              content={comment.content}
              onEdit={() => prepareEdit(comment)}
              onDelete={() => deleteComment(comment.id)}
            />
          ),
        )}
      </Flex>
    </Flex>
  )
}
