import { PlusOutlined } from "@ant-design/icons"
import { DndContext } from "@dnd-kit/core"
import { Button, Card, Flex, Typography } from "antd"
import { usePipeline } from "../../../hooks/usePipeline"
import { spaces } from "../../../utils/styles"
import { pipelineStatus } from "../../../utils/utils"
import { Draggable } from "../../atoms/drag-and-drop/Draggable"
import { Droppable } from "../../atoms/drag-and-drop/Droppable"
import { AssetNameFilter } from "../../atoms/input/AssetNameFilter"
import { AssetClassSelect } from "../../atoms/select/AssetClassSelect"
import { AssetClassTag } from "../../atoms/tags/AssetClassTag"
import { KanbanPane } from "../../molecules/KanbanPane"
import { DetailPipelineAssetModal } from "../../molecules/modals/DetailPipelineAssetModal"
import { NewPipelineAssetModal } from "../../molecules/modals/NewPipelineAssetModal"
import "./Pipeline.css"

export const Pipeline = () => {
  const {
    assets,
    setClassFilter,
    setNameFilter,
    selectedAsset,
    setSelectedAsset,
    updateStatus,
    isManagementTeam,
    showNewAssetModal,
    openNewAssetModal,
    closeNewAssetModal,
    refresh,
  } = usePipeline()

  const STATUS_WIDTH = "35vw"

  const handleDragEnd = async (event) => {
    const { active, over, delta } = event

    const notMovedCursor = delta && delta.x === 0 && delta.y === 0

    const taskId = active.id

    if (notMovedCursor) return setSelectedAsset(assets.find((asset) => asset.id === taskId))

    const notChanged = assets.find((asset) => asset.id === taskId).status === over.id

    if (notChanged) return

    const status = over.id
    await updateStatus(status, taskId)
  }

  const AssetList = (status) => {
    return (
      <Flex vertical gap={spaces.space1}>
        {assets
          .filter((asset) => asset.status === status)
          .map((asset) => (
            <Draggable id={asset.id} key={asset.id}>
              <Card className="asset-card" onClick={() => setSelectedAsset(asset)} style={{ cursor: "pointer" }} key={asset.id}>
                <Flex style={{ justifyContent: "space-between" }}>
                  <Typography.Text ellipsis>{asset.name}</Typography.Text>
                  <AssetClassTag id={asset.class} />
                </Flex>
              </Card>
            </Draggable>
          ))}
      </Flex>
    )
  }

  return (
    <>
      {selectedAsset && (
        <DetailPipelineAssetModal
          onClose={() => {
            setSelectedAsset(null)
            refresh()
          }}
        />
      )}
      {showNewAssetModal && <NewPipelineAssetModal onClose={closeNewAssetModal} />}
      <Flex vertical style={{ margin: spaces.space2, gap: spaces.space2 }}>
        <Flex gap={spaces.space2}>
          <Button key="ok" type="primary" onClick={openNewAssetModal} disabled={!isManagementTeam}>
            <PlusOutlined />
            Cadastrar novo ativo
          </Button>
          <AssetClassSelect setAssetClass={setClassFilter} defaultValue={"Todas"} />
          <AssetNameFilter setValue={setNameFilter} />
        </Flex>
        <Flex style={{ gap: spaces.space2 }}>
          <DndContext onDragEnd={handleDragEnd}>
            {Object.values(pipelineStatus).map((status) => (
              <KanbanPane text={status.text} description={status.description} icon={status.icon} width={STATUS_WIDTH} key={status.text}>
                <Droppable id={status.text} style={{ padding: spaces.space1, height: "65vh" }}>
                  {AssetList(status.text)}
                </Droppable>
              </KanbanPane>
            ))}
          </DndContext>
        </Flex>
      </Flex>
    </>
  )
}
