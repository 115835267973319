import { createContext, useState } from "react"

export const PipelineContext = createContext({
  classFilter: null,
  setClassFilter: () => {},
  nameFilter: null,
  setNameFilter: () => {},
  selectedAsset: null,
  setSelectedAsset: () => {},
})

export const PipelineProvider = ({ children }) => {
  const [classFilter, setClassFilter] = useState(null)
  const [nameFilter, setNameFilter] = useState(null)
  const [selectedAsset, setSelectedAsset] = useState(null)

  return (
    <PipelineContext.Provider value={{ classFilter, setClassFilter, nameFilter, setNameFilter, selectedAsset, setSelectedAsset }}>
      {children}
    </PipelineContext.Provider>
  )
}
