export const CommentService = (supabase, table, foreignKey) => {
  const addComment = async (comment, date, author, id) => {
    await supabase.from(table).insert({ content: comment, author, [foreignKey]: id, created_at: date })
  }

  const deleteComment = async (id) => {
    await supabase.from(table).update({ deleted_at: new Date().toISOString() }).eq("id", id)
  }

  const editComment = async (id, date, content) => {
    await supabase.from(table).update({ content, created_at: date }).eq("id", id)
  }

  const getAllComments = async (parentId) => {
    const { data } = await supabase.from(table).select().eq(foreignKey, parentId).is("deleted_at", null).order("created_at", { ascending: false })
    return data
  }

  return { addComment, deleteComment, editComment, getAllComments }
}
