import { filesQuantFormats } from "../../../utils/utils"
import { StaticFileViewer } from "../../molecules/viewers/StaticFileViewer"

export const Risk = () => {
  const files = [
    {
      title: "Market Risk",
      path: "risco/market-risk",
      folder: "risk/market-risk-management",
      format: filesQuantFormats.PDF,
    },
    {
      title: "Funds Risk",
      path: "risco/funds-risk",
      folder: "risk/onshore-funds-risk-management",
      format: filesQuantFormats.PDF,
    },
  ]

  return <StaticFileViewer files={files} />
}
