import { Avatar } from "antd"
import { useAuth } from "../../hooks/context/useAuth"
import { colors, spaces } from "../../utils/styles"
import { getInitials } from "../../utils/utils"

export const TeraAvatar = ({ name }) => {
  const { name: myName } = useAuth()

  const initials = getInitials(name || myName)

  const userColors = {
    FN: "#1890ff",
    RR: "#f56a00",
    JV: "#7265e6",
    IN: "#ffbf00",
    MQ: "#00a2ae",
    PM: "#52c41a",
    VK: "#eb2f96",
    AC: "#fa541c",
  }

  return (
    <Avatar size={spaces.space5} style={{ backgroundColor: userColors[initials] || colors.green, fontSize: 18, marginTop: spaces.space1 }}>
      {initials}
    </Avatar>
  )
}
