import { useContext, useEffect, useMemo, useState } from "react"
import { AttachmentContext } from "../../context/AttachmentsContext"
import { AttachmentService } from "../../service/AttachmentService"
import { useAuth } from "../context/useAuth"
import { useSupabase } from "../context/useSupabase"
import { useToast } from "../context/useToast"
import { useLoading } from "../utils/useLoading"
import { useStorage } from "../utils/useStorage"
import { removeAccents } from "../../utils/string"

export const useAttachment = () => {
  const [attachments, setAttachments] = useState([])

  const { table, parentId, parentColumn, folder } = useContext(AttachmentContext)

  const { isLoading, setIsLoading, reload, refresh } = useLoading()
  const { createFile, downloadFile, deleteFile } = useStorage({ bucket: "data-lake" })
  const { name } = useAuth()
  const toast = useToast()
  const supabase = useSupabase()

  const service = useMemo(() => AttachmentService(supabase, table, parentColumn), [supabase])

  useEffect(() => {
    parentId && service.getAllAttachments(parentId).then(setAttachments)
  }, [parentId, reload])

  const addAttachment = async (attachment) => {
    const fileWithNameSanitized = new File([attachment], removeAccents(attachment.name), { type: attachment.type })

    setIsLoading(true)

    try {
      const file = await createFile(folder, fileWithNameSanitized)
      await service.addAttachment(file.path.split("/").at(-1), name, parentId)
      refresh()
    } catch (error) {
      if (error.error === "Duplicate") toast.error({ message: "Já existe um arquivo com esse nome" })
    }

    setIsLoading(false)
  }

  const downloadAttachment = async (filename) => downloadFile(folder, filename)

  const deleteAttachment = async (attachment) => {
    setIsLoading(true)

    try {
      await Promise.all([service.deleteAttachment(attachment.id), deleteFile(folder, attachment.filename)])
      refresh()
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, attachments, addAttachment, downloadAttachment, deleteAttachment }
}
