import { ExclamationCircleFilled } from "@ant-design/icons"
import { Card, Flex, Form, InputNumber, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { colors, spaces } from "../../../utils/styles"
import { AssetLiabilityManagementPlot } from "../../molecules/charts/AssetLiabilityManagementPlot"
import { ConsumptionPercentageForm } from "../../organisms/forms/ConsumptionPercentageForm"

export const AssetLiabilityManagement = () => {
  const [expectedReturn, setExpectedReturn] = useState(6)
  const [tax, setTax] = useState(15)
  const [inflation, setInflation] = useState(2)
  const [years, setYears] = useState(20)
  const [patrimony, setPatrimony] = useState(100)
  const [consumption, setConsumption] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({ expectedReturn, tax, inflation, years, patrimony })
  })

  const onValuesChange = (changedValues) => {
    if (changedValues.expectedReturn) setExpectedReturn(changedValues.expectedReturn)
    if (changedValues.tax) setTax(changedValues.tax)
    if (changedValues.inflation) setInflation(changedValues.inflation)
    if (changedValues.years) setYears(changedValues.years)
    if (changedValues.patrimony) setPatrimony(changedValues.patrimony)
  }

  const PercentInput = (props) => <InputNumber {...props} min={0} suffix="%" step={0.1} />

  const Label = (title) => (
    <span>
      {title}{" "}
      <Tooltip title="Anualizado">
        <ExclamationCircleFilled style={{ color: colors.primary }} />
      </Tooltip>
    </span>
  )

  return (
    <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2 }}>
      <Card style={{ backgroundColor: colors.gray }} size="small">
        <Form layout="inline" form={form} onValuesChange={onValuesChange}>
          <Form.Item label={Label("Retorno esperado")} name="expectedReturn">
            <PercentInput />
          </Form.Item>
          <Form.Item label={Label("Impostos")} name="tax">
            <PercentInput />
          </Form.Item>
          <Form.Item label={Label("Inflação")} name="inflation">
            <PercentInput />
          </Form.Item>
          <Form.Item label="Janela de anos" name="years">
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="Patrimônio sob gestão (em milhões)" name="patrimony">
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      </Card>
      <ConsumptionPercentageForm totalYears={years} onSubmit={setConsumption} />
      <AssetLiabilityManagementPlot
        expectedReturn={expectedReturn}
        tax={tax}
        consumption={consumption}
        inflation={inflation}
        maxYears={years}
        patrimony={patrimony}
      />
    </Flex>
  )
}
